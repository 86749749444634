import { Box, ButtonBase } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  UIEventHandler,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { throttle } from "lodash-es";
import ReactPlayer from "react-player/youtube";
import { Visual } from "api";

type ThumbnailViewerProps = {
  visuals: Visual[];
};

const ThumbnailViewer = ({ visuals }: ThumbnailViewerProps) => {
  const [focused, setFocused] = useState(0);
  const [fadeout, setFadeout] = useState(-1);

  const ref = useRef<HTMLDivElement>(null);

  const handleScrollLeft = useCallback(() => {
    if (ref.current != null) {
      const width = ref.current.clientWidth;
      ref.current.scrollBy({
        left: -(width * 9) / 10,
        behavior: "smooth",
      });
    }
  }, [ref]);

  const handleScrollRight = useCallback(() => {
    if (ref.current != null) {
      const width = ref.current.clientWidth;
      ref.current.scrollBy({
        left: (width * 9) / 10,
        behavior: "smooth",
      });
    }
  }, [ref]);

  const [begin, setBegin] = useState<boolean>(true);
  const [end, setEnd] = useState<boolean>(false);

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(() => {
    if (ref.current != null) {
      checkReachedEdge(ref.current, setBegin, setEnd);
    }
  }, [ref]);

  useEffect(() => {
    const onResizeWindow = () => {
      if (ref.current) {
        checkReachedEdge(ref.current, setBegin, setEnd);
      }
    };

    onResizeWindow();
    window.addEventListener("resize", onResizeWindow);
    return () => {
      window.removeEventListener("resize", onResizeWindow);
    };
  }, [ref, setBegin, setEnd]);

  return (
    <Box color="#000">
      <Box
        width="100%"
        paddingBottom="56.25%"
        position="relative"
        overflow="hidden"
      >
        {visuals.map((visual, index) => (
          <VisualCard
            key={visual.url}
            visual={visual}
            fadeout={fadeout === index}
            fadein={focused === index}
            hidden={fadeout !== index && focused !== index}
          />
        ))}
      </Box>
      <div style={{ position: "relative" }}>
        <ImageList ref={ref} onScroll={handleScroll}>
          {visuals.map((visual, index) => {
            const url = thumbnailUrl(visual);
            return (
              <div
                key={visual.url}
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => {
                  setFadeout(focused);
                  setFocused(index);
                }}
              >
                <ImageSwitch
                  key={url}
                  src={url}
                  role="button"
                  data-active={focused === index}
                />
                {visual.kind === "video" && (
                  <PlayArrowIcon
                    fontSize="large"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      opacity: 0.8,
                    }}
                    sx={{ color: "#fff", background: "#000", padding: "4px" }}
                  />
                )}
              </div>
            );
          })}
        </ImageList>
        <ScrollButton align="left" disabled={begin} onClick={handleScrollLeft}>
          <ArrowBackIosNewIcon sx={{ color: "#fff" }} />
        </ScrollButton>
        <ScrollButton align="right" disabled={end} onClick={handleScrollRight}>
          <ArrowForwardIosIcon sx={{ color: "#fff" }} />
        </ScrollButton>
      </div>
    </Box>
  );
};

const thumbnailUrl = (visual: Visual): string => {
  switch (visual.kind) {
    case "image":
      return visual.url;
    case "video":
      return visual.thumbnailUrl;
  }
};

const checkReachedEdge = throttle(
  (
    elem: HTMLDivElement,
    setBegin: (x: boolean) => void,
    setEnd: (x: boolean) => void
  ) => {
    setBegin(elem.scrollLeft === 0);
    setEnd(elem.scrollLeft + elem.clientWidth + 8 >= elem.scrollWidth);
  },
  500 // 0.5sec
);

type VisualCardProps = {
  visual: Visual;
  fadeout: boolean;
  fadein: boolean;
  hidden: boolean;
};

const VisualCard = ({ visual, fadeout, fadein, hidden }: VisualCardProps) => {
  if (visual.kind === "image") {
    return (
      <Image
        src={visual.url}
        data-fadeout={fadeout}
        data-fadein={fadein}
        data-hidden={hidden}
        aria-hidden={hidden}
      />
    );
  }

  return (
    <PlayerWrap
      data-fadeout={fadeout}
      data-fadein={fadein}
      data-hidden={hidden}
      aria-hidden={hidden}
    >
      <ReactPlayer
        url={visual.url}
        playing={fadein}
        volume={0.5}
        width="100%"
        height="100%"
        controls
      />
    </PlayerWrap>
  );
};

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-out;

  &[data-fadeout="true"] {
    opacity: 0;
  }

  &[data-fadein="true"] {
    opacity: 1;
    z-index: 10;
  }

  &[data-hidden="true"] {
    transition: opacity 0s;
    opacity: 0;
  }
`;

const PlayerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-out;

  &[data-fadeout="true"] {
    opacity: 0;
  }

  &[data-fadein="true"] {
    opacity: 1;
    z-index: 10;
  }

  &[data-hidden="true"] {
    transition: opacity 0s;
    opacity: 0;
  }
`;

const ImageList = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;

  background: #000;
  padding: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ImageSwitch = styled.img`
  cursor: pointer;
  width: 100px;
  height: 56px;
  object-fit: cover;

  &[data-active="true"] {
    outline: 2px solid white;
    outline-offset: -2px;
  }
`;

const ScrollButton = styled(ButtonBase)<{ align: "left" | "right" }>`
  width: 40px;
  height: 100%;

  position: absolute;
  top: 0;
  ${({ align }) => align}: 0;

  background: rgba(0, 0, 0, 0.9);
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    display: none;
  }
`;

export default memo(ThumbnailViewer);
