import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D8Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M15.999 2.66669L3.99902 9.33277V22.6661L15.999 29.3334L27.999 22.6661V9.33277L15.999 2.66669Z"
        fill="#202020"
      />
      <path
        d="M15.999 2.66669L3.99902 9.33277V22.6661L15.999 29.3334L27.999 22.6661V9.33277L15.999 2.66669ZM26.9651 21.1655L16.8323 4.27966L26.9638 9.90866V21.1667L26.9651 21.1655ZM26.06 21.6478H5.93922L15.999 4.88013L26.0588 21.6466L26.06 21.6478ZM15.1658 4.27966L5.03298 21.1655V9.90749L15.1658 4.27966ZM6.02558 22.6427H25.9725L15.999 28.1839L6.02558 22.6427Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D8Icon);
