import theme from "theme";
import { ReactComponent as CcfoliaProSvg } from "./CcfoliaPro.svg";

type CcfoliaProIconProps = {
  height?: string;
  fill?: string;
};

const CcfoliaProIcon = ({
  height = "14px",
  fill = theme.palette.text.primary,
}: CcfoliaProIconProps) => {
  return <CcfoliaProSvg height={height} fill={fill} />;
};

export default CcfoliaProIcon;
