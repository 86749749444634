import React, { useRef, useEffect, useCallback, memo, FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import styled from "styled-components";

const r = /[\r\n。、,.]/;

type TypingProps = {
  text: string;
  TypographyProps: TypographyProps;
  onEnded: () => void;
  onRender: () => void;
  t?: string;
};

const Typing: FC<TypingProps> = ({
  text,
  TypographyProps,
  onEnded,
  onRender,
  t,
}) => {
  const input = useRef("");
  const point = useRef(0);
  const timer = useRef<any>(undefined);
  const textEl = useRef<HTMLDivElement | null>(null);
  const render = useCallback(() => {
    if (textEl.current) {
      if (input.current.length > point.current) {
        const char = input.current.charAt(point.current);
        textEl.current.textContent += char;
        point.current++;
        onRender();
        timer.current = setTimeout(render, r.test(char) ? 800 : 80);
      } else {
        setTimeout(onEnded, 1200);
      }
    } else {
      timer.current = setTimeout(render, 400);
    }
  }, [onEnded, onRender]);

  useEffect(() => {
    input.current = text;
    point.current = 0;
    if (textEl.current) {
      textEl.current.textContent = "";
    }
    render();
    return () => {
      clearTimeout(timer.current);
    };
  }, [render, text, t]);

  return <Text {...TypographyProps} ref={textEl}></Text>;
};

const Text = styled(Typography)`
  white-space: pre-wrap;
`;

export default memo(Typing);
