import React, { memo } from "react";
import styled from "styled-components";
import { Modal, LinearProgress } from "@mui/material";

type LoaderProps = {
  loading: boolean;
};

const Loader = (props: LoaderProps) => {
  return (
    <Modal open={props.loading}>
      <Center>
        <LinearProgress />
      </Center>
    </Modal>
  );
};

const Center = styled.div`
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
`;

export default memo(Loader);
