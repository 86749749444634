interface ImageSize {
  width: number;
  height: number;
}

const mesureImage = (
  url: string | undefined,
  cellSize: number,
  defaultSize: ImageSize = { width: 2, height: 2 }
): Promise<ImageSize> => {
  return new Promise((resolve, _) => {
    if (url) {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        const width = Math.round(image.naturalWidth / cellSize);
        const height = Math.round(image.naturalHeight / cellSize);
        resolve({
          width,
          height,
        });
      };
    } else {
      resolve(defaultSize);
    }
  });
};

export default mesureImage;
