import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { memo } from "react";

type LoadingDialogProps = {
  open: boolean;
  text: string;
};

const LoadingDialog = ({ open, text }: LoadingDialogProps) => {
  return (
    <Dialog open={open} onClose={() => {}} maxWidth="xs">
      <DialogContent>
        <DialogContentText align="center">{text}</DialogContentText>
        <Box display="flex" justifyContent="center" margin="16px">
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default memo(LoadingDialog);
