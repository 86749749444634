import React, { FC } from "react";
import theme from "theme";
import styled, { keyframes } from "styled-components";
import { Message } from "stores/modules/entities.room.messages";

type DiceProps = {
  faces: number;
  value: number;
  kind?: string | null;
  index: number;
  secret: boolean;
  timestamp?: Message["createdAt"];
};

const Dice: FC<DiceProps> = (props) => {
  if (props.index > 10) return null;
  return <>{props.faces === 100 ? <D100 {...props} /> : <DX {...props} />}</>;
};

const diceTypes = [4, 6, 8, 10, 12, 20];
const DX: FC<DiceProps> = ({
  faces = 6,
  value = 1,
  index = 0,
  kind = null,
  timestamp = 0,
}) => {
  if (diceTypes.indexOf(faces) < 0) return null;
  if (kind === "tens_d10") {
    return (
      <Image
        src={`/images/tens_d10_dice/100_dice[${value}].png`}
        draggable={false}
        index={index}
        key={`${timestamp}-${index}`}
      />
    );
  }
  return (
    <Image
      src={`/images/${faces}_dice/${faces}_dice[${value}].png`}
      draggable={false}
      index={index}
      key={`${timestamp}-${index}`}
    />
  );
};

const D100: FC<DiceProps> = ({ value = 1, index = 0, timestamp = 0 }) => {
  const [_x, _xx] = ("0" + value).split("").reverse();
  const x = _x === "0" ? "10" : _x;
  const xx = _xx === "0" ? "10" : _xx;
  return (
    <>
      <Image
        src={`/images/100_dice/100_dice[${xx}].png`}
        draggable={false}
        index={index}
        key={`${timestamp}-${index}-1`}
      />
      <Image
        src={`/images/10_dice/10_dice[${x}].png`}
        draggable={false}
        index={index}
        key={`${timestamp}-${index}-2`}
      />
    </>
  );
};

const rotate = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0) translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: rotate(720deg) scale(1) translate(0, 0);
  }
`;

type ImageProps = { index: number };
const Image = styled.img<ImageProps>`
  opacity: 0;
  animation: ${rotate} 600ms ${({ index }: ImageProps) => index * 100}ms
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  width: 75px;
  height: 75px;
  ${theme.breakpoints.down("md")} {
    width: 50px;
    height: 50px;
  }
`;

export default Dice;
