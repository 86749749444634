import { FC } from "react";
import styled from "styled-components";
import { Typography, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const UnderConstruction: FC = () => {
  const [t] = useTranslation();
  return (
    <Container>
      <Paper>
        <Box p={2}>
          <Typography variant="h5" align="center" gutterBottom>
            {t("現在準備中のルームです")}
          </Typography>
          <Typography variant="body2" align="center">
            {t(
              "このルームでは工事中モードが有効になっています。ルームマスター以外の入室ができません。"
            )}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

const Container = styled.div`
  margin: auto auto;
`;

export default UnderConstruction;
