import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

type ErrorDialogProps = {
  url: string;
  onClose: () => void;
};

const ErrorDialog = ({ url, onClose }: ErrorDialogProps) => {
  const [t] = useTranslation();
  const onConfirm = (e) => {
    if (
      !window.confirm(
        t("外部URLに遷移しようとしています。本当によろしいですか？")
      )
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <DialogTitle>{t("PDFの表示に失敗しました")}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ wordBreak: "break-all" }}>
          URL :{" "}
          <Link
            href={url}
            rel="noreferrer"
            target="_blank"
            onClick={onConfirm}
            underline="hover"
          >
            {url}
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </>
  );
};

export default memo(ErrorDialog);
