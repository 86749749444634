import { Box, Card, CardActionArea, Grid, Typography } from "@mui/material";
import { memo } from "react";
import styled from "styled-components";
import theme from "theme";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LibraryItemDefault = () => {
  const [t] = useTranslation();

  return (
    <Grid item xs={6} md={3} xl={2}>
      <Card>
        <CardActionArea component={Link} to="/games">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ aspectRatio: "16 / 9" }}
          >
            <Typography
              fontSize="12px"
              color="textSecondary"
              marginBottom="16px"
              sx={{ display: { xs: "none", sm: "unset" } }}
            >
              {t("ゲームストアで他のゲームを探す")}
            </Typography>
            <BorderText sx={{ padding: { xs: "8px", sm: "8px 24px" } }}>
              {t("ゲームストアに移動する")}
            </BorderText>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const BorderText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  border: 1px solid ${theme.palette.divider};
  border-radius: 8px;
  margin: 8px;
`;

export default memo(LibraryItemDefault);
