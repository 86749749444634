import { Item } from "../entities.room.items";

export type DeckItem = Pick<Item, "imageUrl" | "memo">;
export type UpdateDeckItem = Pick<Item, "imageUrl" | "memo">;

export type Deck = {
  _id?: string;
  x: number;
  y: number;
  z: number;
  width: number;
  height: number;
  locked: boolean; // 配置固定
  freezed: boolean; // サイズ固定
  coverImageUrl: string | null;
  updatedAt: number;
  createdAt: number;
  items: {
    [itemId: string]: DeckItem;
  };
};

export type UpdateDeck = {
  x?: number;
  y?: number;
  z?: number;
  width?: number;
  height?: number;
  locked?: boolean; // 配置固定
  freezed?: boolean; // サイズ固定
  coverImageUrl?: string | null;
  updatedAt?: number;
  createdAt?: number;
  items?: {
    [itemId: string]: UpdateDeckItem;
  };
};

const DeckItemRecord = (item: UpdateDeckItem): DeckItem => {
  return {
    imageUrl: item?.imageUrl || "",
    memo: item?.memo || "",
  };
};

export const DeckItemsRecord = (
  _items?: Record<string, UpdateDeckItem>
): Record<string, DeckItem> => {
  const items = {};
  if (!_items) return items;
  Object.keys(_items).forEach((itemId) => {
    items[itemId] = DeckItemRecord(_items[itemId]);
  });
  return items;
};

export const DeckRecord = (deck: UpdateDeck): Deck => {
  return {
    x: "x" in deck ? Number(deck.x) : -3,
    y: "y" in deck ? Number(deck.y) : -4,
    z: "z" in deck ? Number(deck.z) : 99,
    width: "width" in deck ? Number(deck.width) : 4,
    height: "height" in deck ? Number(deck.height) : 6,
    locked: "locked" in deck ? Boolean(deck.locked) : false,
    freezed: "freezed" in deck ? Boolean(deck.freezed) : false,
    coverImageUrl: deck?.coverImageUrl || null,
    items: DeckItemsRecord(deck.items),
    updatedAt: deck?.updatedAt || Date.now(),
    createdAt: deck?.createdAt || Date.now(),
  };
};
