import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D10Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M17.7437 2.66669L4.66699 11.1315V19.2819L17.7426 29.3334L31.3337 19.2875V11.1259L17.7437 2.66669Z"
        fill="#202020"
      />
      <path
        d="M17.7437 2.66669L4.66699 11.1315V19.2819L17.7426 29.3334L31.3337 19.2875V11.1259L17.7437 2.66669ZM10.2844 20.2889L17.278 25.642V27.7814L6.87502 19.7843L10.2844 20.2889ZM17.8045 5.04892L24.7748 19.7451L17.7437 24.8039L10.999 19.6422L17.8045 5.04892ZM18.2194 25.6297L25.62 20.3046L29.0515 19.7966L18.2194 27.8026V25.6297ZM30.3945 18.6363L25.6277 19.3423L18.4583 4.22873L30.3934 11.6574V18.6351L30.3945 18.6363ZM17.1773 4.16271L10.1174 19.302L5.60839 18.634V11.6518L17.1773 4.16271Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D10Icon);
