export const uidToDigest = async (uid: string): Promise<string> => {
  const hashBuffer = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(uid)
  );

  const buffer = Array.from(new Uint8Array(hashBuffer));
  const hex = buffer.map((x) => x.toString(16).padStart(2, "0")).join("");
  return hex.slice(0, 10);
};
