import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type RoomPackageCardProps = {
  name: string;
  ownerDigest: string;
  ownerName: string;
  thumbnailUrl: string | null | undefined;
  updatedAt: string;
  existedRoomId: string | null;
  onClick: () => void;
};

export const RoomPackageCard = ({
  thumbnailUrl,
  name,
  updatedAt,
  ownerDigest,
  ownerName,
  existedRoomId,
  onClick,
}: RoomPackageCardProps) => {
  const [t] = useTranslation();
  const formatedUpdateAt = useMemo(() => {
    const date = new Date(updatedAt);
    return format(date, "yyyy/MM/dd HH:mm");
  }, [updatedAt]);

  return (
    <StyledPaper>
      <Box mb={2}>
        <Typography variant="h6">
          {existedRoomId
            ? t("作成済みのルームがあります")
            : t("ルームを作成する")}
        </Typography>
      </Box>
      {thumbnailUrl && (
        <img
          width="100%"
          style={{
            aspectRatio: "16 / 9",
            borderRadius: "4px",
            objectFit: "cover",
          }}
          src={thumbnailUrl || ""}
        />
      )}
      <Box mt={2} mb={2}>
        <Typography variant="h6" style={{ fontSize: "16px" }}>
          {name}
        </Typography>
        <Typography variant="caption" display="block" color="textSecondary">
          {t("最終更新日")} : {formatedUpdateAt}
        </Typography>
        <Typography variant="caption" display="block" color="textSecondary">
          {t("発行者")} : {ownerName || t("ユーザー名未設定")} ({ownerDigest})
        </Typography>
      </Box>
      {existedRoomId != null ? (
        <>
          <Divider />
          <Box mt={2}>
            <Typography variant="body2" color="textPrimary" paragraph>
              {t(
                "ルーム一覧に作成済みのルームがあります。前回作成したルームに入室しますか？"
              )}
            </Typography>
          </Box>
          <Box>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              href={`/rooms/${existedRoomId}`}
            >
              {t("作成済みのルームに入室する")}
            </Button>
            <Button
              fullWidth
              onClick={onClick}
              color="primary"
              style={{ marginTop: "8px" }}
            >
              {t("新規ルーム作成")}
            </Button>
          </Box>
        </>
      ) : (
        <Box mb={2}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onClick}
          >
            {t("新規ルーム作成")}
          </Button>
        </Box>
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  max-width: 448px;
  padding: 16px 24px;
`;

export default RoomPackageCard;
