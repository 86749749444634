import { CircularProgress, MenuItem, MenuItemProps } from "@mui/material";
import { MouseEvent, memo, useCallback, useState } from "react";

type LoadingPromiseMenuItemProps = Omit<MenuItemProps, "onClick"> & {
  onClick: (event: MouseEvent) => Promise<void>;
};

const LoadingPromiseMenuItem = ({
  onClick,
  children,
  ...props
}: LoadingPromiseMenuItemProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      setLoading(true);
      onClick(event).finally(() => setLoading(false));
    },
    [onClick, setLoading]
  );

  return (
    <MenuItem onClick={handleClick} {...props}>
      {children}
      {loading && (
        <CircularProgress
          color="primary"
          size={14}
          style={{ marginLeft: "8px" }}
        />
      )}
    </MenuItem>
  );
};

export default memo(LoadingPromiseMenuItem);
