import {
  DocumentData,
  DocumentReference,
  UpdateData,
  WithFieldValue,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";
import { db } from "initializer";

const MAX_BATCH_SIZE = 500;

export class HugeBatch {
  private stack: WriteBatch[];
  private batch: WriteBatch;
  private size: number;

  constructor() {
    this.batch = writeBatch(db);
    this.stack = [this.batch];
    this.size = 0;
  }

  update<T extends DocumentData>(
    documentRef: DocumentReference<T>,
    data: UpdateData<T>
  ) {
    this.batch.update(documentRef, data);
    this.increment();
  }

  set<T>(documentRef: DocumentReference<T>, data: WithFieldValue<T>) {
    this.batch.set(documentRef, data);
    this.increment();
  }

  delete(documentRef: DocumentReference<unknown>) {
    this.batch.delete(documentRef);
    this.increment();
  }

  async commit() {
    for (const batch of this.stack) {
      await batch.commit();
    }
  }

  private increment() {
    this.size++;
    if (this.size % MAX_BATCH_SIZE === 0) {
      const batch = writeBatch(db);
      this.stack.push(batch);
      this.batch = batch;
    }
  }
}
