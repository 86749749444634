import { ButtonHTMLAttributes } from "react";
import { ReactComponent as GoogleIcon } from "./GoogleIcon.svg";
import styled from "styled-components";
import { Typography } from "@mui/material";

const GoolgeLoginButton = ({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledButton {...props}>
      <GoogleIcon />
      <Typography variant="button" paddingRight="10px" textTransform="none">
        {children}
      </Typography>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    background-color: rgb(239, 240, 238);
  }
`;

export default GoolgeLoginButton;
