export type UserMedium = {
  _id?: string;
  contentType: string;
  dir: string;
  loop: boolean;
  name: string;
  by: string;
  order: number;
  owner: string;
  roomId: string;
  size: number;
  uploaded: boolean;
  url: string | null;
  external: boolean;
  volume: number;
  createdAt: number;
  updatedAt: number;
};
export type UpdateUserMedium = Partial<UserMedium>;

const map = {
  bgm: "bgm01",
  sound: "bgm02",
  effect: "se01",
};
const migrateDirField = (dir: string) => {
  if (map[dir]) {
    return map[dir];
  } else if (dir) {
    return dir;
  } else {
    return "etc";
  }
};

export const UserMediumRecord = (data: any): UserMedium => {
  return {
    owner: data.owner,
    roomId: data.roomId,
    dir: migrateDirField(data.dir),
    volume: "volume" in data ? data.volume : 0.5,
    loop: "loop" in data ? data.loop : true,
    order: data.order || -1,
    contentType: data.contentType || null,
    name: data.name || "",
    by: data.by || "",
    size: data.size || 0,
    uploaded: data.uploaded || false,
    url: data.url || null,
    external: data.external ?? false,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };
};
