import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreateRoomFromProductDialogContent from "./CreateRoomFromProductDialogContent";
import { useTranslation } from "react-i18next";

type CreateRoomFromProductDialogProps = {
  productId: string;
  open: boolean;
  defaultChecked?: string;
  onClose: () => void;
};

const CreateRoomFromProductDialog = ({
  productId,
  open,
  defaultChecked,
  onClose,
}: CreateRoomFromProductDialogProps) => {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }}>{t("ルームを作成する")}</Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <CreateRoomFromProductDialogContent
          productId={productId}
          defaultChecked={defaultChecked}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoomFromProductDialog;
