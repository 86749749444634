import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { memo } from "react";

type LoadingSubmitButtonProps = ButtonProps & {
  loading: boolean;
};

const LoadingSubmitButton = ({
  loading,
  children,
  ...props
}: LoadingSubmitButtonProps) => {
  if (loading) {
    return (
      <Button
        variant="outlined"
        fullWidth={props.fullWidth}
        size={props.size}
        disabled
      >
        <CircularProgress color="primary" size={24.5} />
      </Button>
    );
  }

  return <Button {...props}>{children}</Button>;
};

export default memo(LoadingSubmitButton);
