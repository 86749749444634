import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || "";
// const SOCKET_URL = "ws://localhost:8080";

export const socket = io(SOCKET_URL, {
  autoConnect: false,
  reconnection: true,
  transports: ["websocket"],
});

export const connectSocket = (roomId: string, uid: string) => {
  socket.connect();

  socket.on("disconnect", (reason) => {
    console.log("disconnected", reason);
  });
  socket.on("connect", () => {
    console.log("connected");
    console.log({ roomId, uid, displayName: "", color: "#000000" });
    socket.emit("join", { roomId, uid, displayName: "", color: "#000000" });
  });
  socket.on("connect_error", (error) => {
    console.error("connect_errored", error);
  });
  socket.io.on("reconnect", () => {
    console.log("reconnected");
  });
  socket.io.on("reconnect_attempt", (attempt) => {
    console.log("reconnect_attempted");
  });
  socket.io.on("reconnect_error", (error) => {
    console.error("reconnect_errored", error);
  });
  socket.io.on("reconnect_failed", () => {
    console.error("reconnect_failed");
  });
  socket.io.on("error", (error) => {
    console.error("errored", error);
  });

  return () => {
    socket.off();
    socket.io.off();
    socket.disconnect();
  };
};

export const registerListener = (
  event: string,
  listener: (...args: any[]) => void
) => {
  socket.on(event, listener);

  return () => {
    socket.off(event, listener);
  };
};
