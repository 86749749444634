export default function sendEvent(
  action: Gtag.EventNames | string,
  option?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
) {
  if (window.gtag && process.env.NODE_ENV !== "development") {
    window.gtag("event", action, option);
  } else {
    console.log("gtag", action, option);
  }
}
