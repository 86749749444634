import React, { memo } from "react";
import styled from "styled-components";
import theme from "theme";
import { useMediaQuery } from "@mui/material";

import RoomListItem from "../RoomListItem";

type RoomListProps = {
  list: string[];
};

const RoomList = (props: RoomListProps) => {
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  let cols = 2;
  if (md) {
    cols = 4;
  } else if (lg) {
    cols = 8;
  }

  return (
    <Wrapper>
      {props.list.map((roomId) => (
        <Content cols={cols} key={roomId}>
          <RoomListItem roomId={roomId} />
        </Content>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 8px;
  padding-bottom: 128px;
  display: flex;
  flex-wrap: wrap;
`;
const Content = styled.div<{ cols: number }>`
  box-sizing: border-box;
  width: ${({ cols }) => 100 / cols}%;
  padding: 8px;
`;

export default memo(RoomList);
