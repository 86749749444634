import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D20Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M16.0003 2.66669L2.66699 9.33279V22.6661L16.0003 29.3334L29.3337 22.6661V9.33279L16.0003 2.66669Z"
        fill="#202020"
      />
      <path
        d="M16.0003 2.66669L2.66699 9.33279V22.6661L16.0003 29.3334L29.3337 22.6661V9.33279L16.0003 2.66669ZM24.2725 11.0573L17.7236 4.63008L27.6272 9.58172L24.2725 11.0573ZM8.66168 11.7088L16.0651 4.44282L23.4684 11.7088L16.0651 22.8904L8.66168 11.7088ZM7.85376 11.0607L4.38383 9.57611L14.5398 4.49776L7.85376 11.0607ZM7.3022 11.8882L3.76753 20.3148V10.3767L7.3022 11.8882ZM8.09976 12.7785L15.6378 24.1642L4.23752 21.9877L8.09976 12.7785ZM24.0213 12.7897L27.767 21.9866L16.5092 24.1361L24.0213 12.7897ZM24.8215 11.886L28.2318 10.3868V20.2598L24.8215 11.8871V11.886ZM6.21849 23.3411L16.0003 25.2092L25.7822 23.3423L16.0003 28.2334L6.21849 23.3423V23.3411Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D20Icon);
