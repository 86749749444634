import { memo, useState } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import styled from "styled-components";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SyncButton = (props: { onClick: () => Promise<void> }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    props.onClick().finally(() => setLoading(false));
  };

  return (
    <Content>
      <Typography variant="caption">{t("プラン状況の更新")}</Typography>
      <MarginedIconButton onClick={handleClick} size="small">
        {loading ? (
          <AnimatedSyncIcon fontSize="small" />
        ) : (
          <SyncIcon fontSize="small" />
        )}
      </MarginedIconButton>
    </Content>
  );
};

const Content = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const MarginedIconButton = styled(IconButton)`
  margin-left: 4px;
  margin-right: 8px;
`;

const AnimatedSyncIcon = styled(SyncIcon)`
  animation: 2s linear infinite running rotate;

  @keyframes rotate {
    0% {
      transform: rotate(0turn);
    }

    50% {
      transform: rotate(-0.5turn);
    }

    100% {
      transform: rotate(-1turn);
    }
  }
`;

export default memo(SyncButton);
