import { Box, Typography } from "@mui/material";
import { Product } from "api";
import { memo } from "react";
import { useTranslation } from "react-i18next";

type ProductSummaryProps = {
  category: string;
  players: string;
  playTimes: string;
  developer: string;
  kind: Product["kind"];
  price: number;
};

const ProductSummary = ({
  category,
  players,
  playTimes,
  developer,
  kind,
  price,
}: ProductSummaryProps) => {
  const [t] = useTranslation();

  return (
    <>
      <Description title={t("ジャンル")} text={category} />
      <Description title={t("プレイ人数")} text={players} />
      <Description title={t("プレイ時間")} text={playTimes} />
      <Description title={t("開発元")} text={developer} />
      <Typography
        color="textPrimary"
        fontSize="18px"
        marginTop="10px"
        marginBottom="2px"
      >
        {kind === "game" ? t("ゲーム本体") : t("追加・拡張コンテンツ")}
      </Typography>
      <Box marginBottom="24px">
        {price > 0 ? (
          <>
            <Typography
              color="textPrimary"
              fontSize="24px"
              fontWeight="bold"
              display="inline"
            >
              ¥{price.toLocaleString("ja-JP")}
            </Typography>
            <Typography color="textPrimary" fontSize="12px" display="inline">
              {t("（税込）")}
            </Typography>
          </>
        ) : (
          <Typography
            color="textPrimary"
            fontSize="24px"
            fontWeight="bold"
            display="inline"
          >
            {t("無料")}
          </Typography>
        )}
      </Box>
    </>
  );
};

type DescriptionProps = {
  title: string;
  text: string;
};

const Description = ({ title, text }: DescriptionProps) => {
  if (!text) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" marginBottom="6px">
      <Typography
        color="textPrimary"
        fontSize="14px"
        fontWeight="bold"
        lineHeight="1.5rem"
      >
        {title}：
      </Typography>
      <Typography
        color="textPrimary"
        fontSize="16px"
        lineHeight="1.5rem"
        marginLeft="16px"
      >
        {text}
      </Typography>
    </Box>
  );
};

export default memo(ProductSummary);
