import { uploadFile as uploadFileApi, deleteFile as deleteFileApi } from "api";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  deleteDoc,
  doc,
  setDoc,
} from "firebase/firestore";

type FileDocument = {
  collectionRef: CollectionReference;
  data: DocumentData;
};

export const uploadFile = async (
  file: File,
  { collectionRef, data }: FileDocument,
  filename?: string
) => {
  const docRef = filename ? doc(collectionRef, filename) : doc(collectionRef);

  await setDoc(docRef, {
    ...data,
    name: file.name || "",
    uploaded: false,
    archived: false,
    url: null,
    contentType: file.type,
    size: 0,
    updatedAt: Date.now(),
    createdAt: Date.now(),
  });

  const uploadResult = await uploadFileApi({
    file,
    filePath: docRef.path,
  }).catch(() => {
    alert(
      `ファイルアップロードに失敗しました。ファイル容量が大きすぎる可能性があります。`
    );
  });

  if (uploadResult) {
    const url = [process.env.REACT_APP_CDN_URL, uploadResult.name].join("/");
    try {
      await setDoc(
        docRef,
        {
          uploaded: true,
          url,
          contentType: uploadResult.contentType,
          size: uploadResult.size,
          updatedAt: Date.now(),
        },
        { merge: true }
      );
      return { doc: docRef, file, url };
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  // 直 upload
  // const storageRef = storage.ref(docRef.path);
  // const uploaded = await storageRef.put(file).catch(() => {
  //   alert(
  //     `ファイルアップロードに失敗しました。ファイル容量が大きすぎる可能性があります。`
  //   );
  // });

  // if (!uploaded) {
  //   docRef.delete();
  //   return;
  // }
  // const metadata = await storageRef.updateMetadata({
  //   cacheControl: `public,max-age=${maxAge}`, // 30days
  // });
  // const downloadURL = await storageRef.getDownloadURL();
  // await docRef.set(
  //   {
  //     uploaded: true,
  //     url: downloadURL,
  //     contentType: metadata.contentType,
  //     size: metadata.size,
  //     updatedAt: Date.now(),
  //   },
  //   { merge: true }
  // );
  // return { doc: docRef, file, url: downloadURL };
};

export const deleteFile = async (docRef: DocumentReference<unknown>) => {
  try {
    await deleteFileApi(docRef.path);
  } catch (err) {
    console.error(err);
  }
  return deleteDoc(docRef);
};
