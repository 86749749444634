import { FC, useEffect } from "react";

import RoomContainer from "containers/Room";
import { RouteComponentProps } from "react-router-dom";

type RoomProps = RouteComponentProps<{ roomId: string }>;

const Room: FC<RoomProps> = ({ match }) => {
  const { roomId } = match.params;
  useEffect(() => {
    document.body.className = "noscroll";
    return () => {
      document.body.className = "";
    };
  }, []);
  return <RoomContainer roomId={roomId} />;
};

export default Room;
