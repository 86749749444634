import { Link, Typography } from "@mui/material";
import { memo } from "react";
import styled from "styled-components";
import theme from "theme";

type DetailListItemProps = {
  title: string;
  text: string;
  href?: string;
};

const DetailListItem = ({ title, text, href }: DetailListItemProps) => {
  if (!text) {
    return null;
  }

  return (
    <Li>
      <Title>
        <Typography
          lineHeight="22.4px"
          fontSize="14px"
          fontWeight="bold"
          color="textSecondary"
        >
          {title}
        </Typography>
      </Title>
      <div>
        {href ? (
          <Link
            href={href}
            variant="body1"
            target="_blank"
            lineHeight="22.4px"
            underline="hover"
          >
            {text}
          </Link>
        ) : (
          <Typography lineHeight="22.4px">{text}</Typography>
        )}
      </div>
    </Li>
  );
};

const Li = styled.li`
  display: flex;
  padding: 18px 24px;
  color: ${theme.palette.text.primary};
  border-top: 1px solid ${theme.palette.divider};

  &:last-child {
    border-bottom: 1px solid ${theme.palette.divider};
  }
`;

const Title = styled.div`
  width: 160px;
  flex-shrink: 0;
`;

export default memo(DetailListItem);
