import { Box, Card, CardMedia, Typography } from "@mui/material";
import TouchRipple, {
  TouchRippleActions,
} from "@mui/material/ButtonBase/TouchRipple";
import { MouseEvent, memo, useCallback, useRef } from "react";
import theme from "theme";

type ExtentionCardProps = {
  name: string;
  thumbnailUrl: string;
  description: string;
  disabled: boolean;
};

const ExtentionCard = ({
  name,
  thumbnailUrl,
  description,
  disabled,
}: ExtentionCardProps) => {
  const refRipple = useRef<TouchRippleActions>(null);

  const start = useCallback(
    (event: MouseEvent) => {
      if (refRipple.current && !disabled) {
        refRipple.current?.start(event);
      }
    },
    [disabled]
  );

  const stop = useCallback((event: MouseEvent) => {
    if (refRipple.current) {
      refRipple.current.stop(event);
    }
  }, []);

  return (
    <Card
      sx={{
        display: "flex",
        userSelect: "none",
        backgroundColor: theme.palette.grey[900],
        cursor: disabled ? undefined : "pointer",
        opacity: disabled ? 0.5 : undefined,
      }}
      elevation={0}
    >
      <Box
        display="flex"
        overflow="hidden"
        onMouseDown={start}
        onMouseUp={stop}
        position="relative"
        sx={{
          flexDirection: {
            md: "row",
            xs: "column",
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: { md: 140, xs: "100%" },
            height: "100%",
          }}
          image={thumbnailUrl}
        />
        <Box display="flex" flexDirection="column" padding="16px" flexGrow="1">
          <Typography fontSize="16px" marginBottom="4px">
            {name}
          </Typography>
          <Typography
            fontSize="14px"
            color="textSecondary"
            display="inline-block"
            textOverflow="ellipsis"
            lineHeight="19.6px"
            height="39.2px"
            overflow="hidden"
            sx={{
              display: {
                md: "inline-block",
                xs: "none",
              },
            }}
          >
            {description}
          </Typography>
        </Box>
        <TouchRipple ref={refRipple} center={false} />
      </Box>
    </Card>
  );
};

export default memo(ExtentionCard);
