import { Link, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Footer = () => {
  const [t] = useTranslation();
  const year = new Date().getFullYear();

  return (
    <StyledFooter>
      <Typography color="textSecondary" align="center">
        &copy; {year} ccfolia.com
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Typography variant="caption" color="textSecondary">
          <Link href="/termsOfService.html" target="_blank" underline="hover">
            {t("利用規約")}
          </Link>
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ marginLeft: 8 }}
        >
          <Link
            href="/termsOfSoundLibrary.html"
            target="_blank"
            underline="hover"
          >
            {t("音源利用規約")}
          </Link>
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ marginLeft: 8 }}
        >
          <Link href="/termsForSales.html" target="_blank" underline="hover">
            {t("特定商取引法に基づく表示")}
          </Link>
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ marginLeft: 8 }}
        >
          <Link
            href="https://docs.ccfolia.com/information/support"
            target="_blank"
            underline="hover"
          >
            {t("お問い合わせ")}
          </Link>
        </Typography>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  margin-top: 16px;
  padding: 32px;
  position: relative;
  z-index: 1;
`;

export default memo(Footer);
