type Action = ActionBuyProduct | ActionGetFreeProduct;

type ActionBuyProduct = {
  kind: "buyProduct";
  productId: string;
  expiredAt: string;
};

type ActionGetFreeProduct = {
  kind: "getFreeProduct";
  productId: string;
  expiredAt: string;
};

const KEY = "ccfolia.actionAfterLogin";
const THIRTY_MIN_MSEC = 30 * 60 * 1000;

export const setActionBuyProduct = (productId: string) => {
  const action: ActionBuyProduct = {
    kind: "buyProduct",
    productId,
    expiredAt: new Date(Date.now() + THIRTY_MIN_MSEC).toISOString(),
  };
  window.sessionStorage.setItem(KEY, JSON.stringify(action));
};

export const setActionGetFreeProduct = (productId: string) => {
  const action: ActionGetFreeProduct = {
    kind: "getFreeProduct",
    productId,
    expiredAt: new Date(Date.now() + THIRTY_MIN_MSEC).toISOString(),
  };
  window.sessionStorage.setItem(KEY, JSON.stringify(action));
};

export const getActionAfterLogin = () => {
  const item = window.sessionStorage.getItem(KEY);
  if (item == null) {
    return null;
  }

  const action: Action = JSON.parse(item);
  if (Date.parse(action.expiredAt) <= Date.now()) {
    return null;
  }

  return action;
};

export const clearActionAfterLogin = () =>
  window.sessionStorage.removeItem(KEY);
