import { ButtonBase } from "@mui/material";
import styled from "styled-components";
import theme from "theme";

const ShareButton = styled(ButtonBase)`
  background: #000;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background: ${theme.palette.grey[700]};
  }
`;

export default ShareButton;
