const ALLOWED_PROTOCOL = ["http:", "https:"];

const isValidUrl = (str: string): boolean => {
  try {
    const url = new URL(str);
    return ALLOWED_PROTOCOL.includes(url.protocol);
  } catch {
    return false;
  }
};

export default isValidUrl;
