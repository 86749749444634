import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  Container,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Product } from "api";
import { useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Slider, { Settings as SliderSettings } from "react-slick";
import styled from "styled-components";
import theme from "theme";
import { useTranslation } from "react-i18next";
import { setRefererInternal } from "modules/referer";

type CarouselProps = {
  products: Product[];
};

const Carousel = ({ products }: CarouselProps) => {
  const sliderRef = useRef<Slider>(null);

  const settings: SliderSettings = {
    arrows: false,
    className: "center",
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  };

  const moveForward = useCallback(() => {
    sliderRef.current?.slickNext();
  }, [sliderRef]);

  const moveBackward = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, [sliderRef]);

  return (
    <Box position="relative" mt="16px" overflow="hidden">
      <SliderContainer maxWidth="md">
        <Slider {...settings} ref={sliderRef}>
          {products.map((product) => {
            return (
              <CarouselItem
                key={product.id}
                productId={product.id}
                {...product}
              />
            );
          })}
        </Slider>
      </SliderContainer>
      <ArrowButton align="right" onClick={moveForward}>
        <ArrowForwardIosIcon sx={{ color: "text.primary" }} />
      </ArrowButton>
      <ArrowButton align="left" onClick={moveBackward}>
        <ArrowBackIosNewIcon sx={{ color: "text.primary" }} />
      </ArrowButton>
    </Box>
  );
};

const SliderContainer = styled(Container)`
  .item-description {
    opacity: 0;
  }

  .slick-center .item-description {
    opacity: 1;
  }

  .slick-list {
    overflow: visible;
  }
`;

const ArrowButton = styled(ButtonBase)<{ align: "left" | "right" }>`
  background: #000;
  padding: 16px;
  border-radius: 0;

  position: absolute;
  top: calc(50% - 16px - 12px);
  ${({ align }) => align}: 0;

  &:hover {
    background: ${theme.palette.grey[800]};
  }
  ${theme.breakpoints.down("sm")} {
    padding: 8px;
    top: calc(50% - 8px - 12px);
  }
`;

const ONE_WEEK_MSEC = 7 * 24 * 60 * 60 * 1000;

type CarouselItemProps = {
  productId: string;
  thumbnailUrl: string;
  category: string;
  name: string;
  players: string;
  playTimes: string;
  developer: string;
  publishedAt: string;
  description: string;
};

const CarouselItem = ({
  productId,
  thumbnailUrl,
  category,
  name,
  players,
  playTimes,
  developer,
  publishedAt,
  description,
}: CarouselItemProps) => {
  const [t] = useTranslation();

  const isNew = useMemo(
    () => Date.parse(publishedAt) > Date.now() - ONE_WEEK_MSEC,
    [publishedAt]
  );

  const onClickCard = useCallback(() => {
    setRefererInternal({ productId, referer: "store-top-page-main-visual" });
  }, [productId]);

  return (
    <Box className="carousel-item" sx={{ padding: "0 4px" }}>
      <Card sx={{ position: "relative", borderRadius: 0 }} elevation={0}>
        <CardActionArea
          component={Link}
          to={`/games/${productId}`}
          onClick={onClickCard}
        >
          <img src={thumbnailUrl} width="100%" alt="" />
          <Box
            className="item-description"
            sx={{
              position: "absolute",
              left: { sm: "24px", xs: "16px" },
              bottom: { sm: "24px", xs: "16px" },
              maxWidth: { md: "60%", xs: "calc(100% - 32px)" },
              transition: "opacity 0.5s ease-out",
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              fontWeight="bold"
              marginBottom="16px"
              sx={{
                marginBottom: { md: "16px", xs: "4px" },
                fontSize: { md: "18px", xs: "14px" },
                textShadow: "1px 1px 2px rgba(0,0,0,0.7)",
              }}
            >
              {category}
              {isNew && <LabelNew>NEW!!</LabelNew>}
            </Typography>
            <Typography
              fontWeight="bold"
              sx={{
                marginBottom: { md: "16px", xs: 0 },
                fontSize: { md: "24px", xs: "18px" },
                whiteSpace: { md: "unset", xs: "nowrap" },
                textOverflow: "ellipsis",
                overflow: "hidden",
                textShadow: "1px 1px 2px rgba(0,0,0,0.7)",
              }}
            >
              {name}
            </Typography>
            <Box
              sx={{
                display: { md: "block", xs: "none" },
                marginBottom: { md: "16px", xs: 0 },
                textShadow: "1px 1px 2px rgba(0,0,0,0.7)",
              }}
            >
              {players && (
                <Typography marginBottom="2px">
                  <Typography
                    component="span"
                    fontSize="14px"
                    fontWeight="bold"
                    display="inline"
                  >
                    {t("プレイ人数")}：
                  </Typography>
                  {players}
                </Typography>
              )}
              {playTimes && (
                <Typography marginBottom="2px">
                  <Typography
                    component="span"
                    fontSize="14px"
                    fontWeight="bold"
                    display="inline"
                  >
                    {t("プレイ時間")}：
                  </Typography>
                  {playTimes}
                </Typography>
              )}
              {developer && (
                <Typography marginBottom="2px">
                  <Typography
                    component="span"
                    fontSize="14px"
                    fontWeight="bold"
                    display="inline"
                  >
                    {t("開発元")}：
                  </Typography>
                  {developer}
                </Typography>
              )}
            </Box>
            <Typography
              fontSize="14px"
              color="#f5f5f5"
              sx={{
                display: { md: "block", xs: "none" },
                textShadow: "1px 1px 1px rgba(0,0,0,0.6)",
              }}
            >
              {description}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
};

const LabelNew = styled.span`
  padding-left: 8px;
  color: ${theme.palette.secondary.main};
`;

export default Carousel;
