import {
  restrictToString,
  restrictToNumber,
  restrictToStringOrNull,
  restrictToBoolean,
  restrictToArray,
} from "modules/restrict";
import { DiceSkinSettings } from "../entities.user.setting";

export type Character = {
  _id?: string;
  name: string;
  playerName: string;
  memo: string;
  initiative: number;
  externalUrl: string;
  status: {
    label: string;
    value: number;
    max: number;
  }[];
  params: { label: string; value: string }[];
  // imageId: character.imageId || null,
  iconUrl: string | null;
  faces: { iconUrl: string | null; label: string }[];
  x: number;
  y: number;
  z: number;
  angle: number;
  width: number;
  height: number;
  active: boolean;
  secret: boolean;
  invisible: boolean;
  hideStatus: boolean;
  color: string;
  roomId: string | null;
  commands: string;
  owner: string | null;
  speaking: boolean;
  diceSkin: DiceSkinSettings;
  updatedAt: number;
  createdAt: number;
};

export type UpdateCharacter = {
  _id?: string;
  name?: string;
  playerName?: string;
  memo?: string;
  initiative?: number;
  externalUrl?: string;
  status?: {
    label: string;
    value: number;
    max: number;
  }[];
  params?: { label: string; value: string }[];
  // imageId?: character.imageId || null,
  iconUrl?: string | null;
  faces?: { iconUrl?: string | null; label?: string }[];
  x?: number;
  y?: number;
  z?: number;
  angle?: number;
  width?: number;
  height?: number;
  active?: boolean;
  secret?: boolean;
  invisible?: boolean;
  hideStatus?: boolean;
  color?: string;
  roomId?: string | null;
  commands?: string;
  owner?: string | null;
  speaking?: boolean;
  diceSkin?: DiceSkinSettings;
  updatedAt?: number;
  createdAt?: number;
};

const storageUrl = process.env.REACT_APP_STORAGE_URL || "";
const cdnUrl = process.env.REACT_APP_CDN_URL || "";
const filterByStorageUrl = (url: string | null) => {
  if (url === null) return null;
  return url.indexOf(storageUrl) === 0 || url.indexOf(cdnUrl) === 0
    ? url
    : null;
};
const CharacterFacesField = (faces: UpdateCharacter["faces"]) => {
  if (!faces) return [];
  return faces.map((face) => {
    return {
      label: restrictToString(face.label, ""),
      iconUrl: restrictToStringOrNull(face.iconUrl),
    };
  });
};
const CharacterFacesField_V2 = (faces: UpdateCharacter["faces"]) => {
  if (!faces) return [];
  return faces.map((face) => {
    return {
      label: restrictToString(face.label, ""),
      iconUrl: filterByStorageUrl(restrictToStringOrNull(face.iconUrl)),
    };
  });
};
const paramsRecord = (params: Character["params"]): Character["params"] => {
  return params.map((p) => ({
    label: restrictToString(p.label, ""),
    value: restrictToString(p.value, ""),
  }));
};
const statusRecord = (status: Character["status"]): Character["status"] => {
  return status.map((p) => ({
    label: String(p.label),
    value: isNaN(p.value) ? 0 : parseFloat(String(p.value)),
    max: isNaN(p.max) ? 0 : parseFloat(String(p.max)),
  }));
};
export const CharacterRecord = (character: UpdateCharacter): Character => {
  return {
    name: restrictToString(character.name, ""),
    playerName: restrictToString(character.playerName, ""),
    memo: restrictToString(character.memo, ""),
    initiative: restrictToNumber(character.initiative, 0),
    externalUrl: restrictToString(character.externalUrl, ""),
    status: statusRecord(
      restrictToArray(character.status, [
        {
          label: "HP",
          value: 0,
          max: 0,
        },
        {
          label: "MP",
          value: 0,
          max: 0,
        },
        {
          label: "SAN",
          value: 0,
          max: 0,
        },
      ])
    ),
    params: paramsRecord(
      restrictToArray(character.params, [{ label: "", value: "" }])
    ),
    // imageId: character.imageId || null,
    iconUrl: restrictToStringOrNull(character.iconUrl),
    faces: CharacterFacesField(restrictToArray(character.faces, [])),
    x: restrictToNumber(character.x, 0),
    y: restrictToNumber(character.y, 0),
    z: restrictToNumber(character.z, 0),
    angle: restrictToNumber(character.angle, 0),
    width: restrictToNumber(character.width, 4),
    height: restrictToNumber(character.height, 4),
    active: restrictToBoolean(character.active, false),
    secret: restrictToBoolean(character.secret, false),
    invisible: restrictToBoolean(character.invisible, false),
    hideStatus: restrictToBoolean(character.hideStatus, false),
    color: restrictToString(character.color, "#888888"),
    roomId: restrictToStringOrNull(character.roomId),
    commands: restrictToString(character.commands, ""),
    owner: restrictToStringOrNull(character.owner),
    speaking: restrictToBoolean(character.speaking, false),
    diceSkin: DiceSkinRecord(character.diceSkin),
    createdAt: restrictToNumber(character.updatedAt, 0),
    updatedAt: restrictToNumber(character.updatedAt, 0),
  };
};

export const CharacterRecord_V2 = (character: UpdateCharacter): Character => {
  return {
    name: restrictToString(character.name, ""),
    playerName: restrictToString(character.playerName, ""),
    memo: restrictToString(character.memo, ""),
    initiative: restrictToNumber(character.initiative, 0),
    externalUrl: restrictToString(character.externalUrl, ""),
    status: statusRecord(
      restrictToArray(character.status, [
        {
          label: "HP",
          value: 0,
          max: 0,
        },
      ])
    ),
    params: paramsRecord(
      restrictToArray(character.params, [{ label: "", value: "" }])
    ),
    // imageId: character.imageId || null,
    iconUrl: filterByStorageUrl(restrictToStringOrNull(character.iconUrl)),
    faces: CharacterFacesField_V2(restrictToArray(character.faces, [])),
    x: restrictToNumber(character.x, 0),
    y: restrictToNumber(character.y, 0),
    z: restrictToNumber(character.z, 0),
    angle: restrictToNumber(character.angle, 0),
    width: restrictToNumber(character.width, 4),
    height: restrictToNumber(character.height, 4),
    active: restrictToBoolean(character.active, false),
    secret: restrictToBoolean(character.secret, false),
    invisible: restrictToBoolean(character.invisible, false),
    hideStatus: restrictToBoolean(character.hideStatus, false),
    color: restrictToString(character.color, "#888888"),
    roomId: restrictToStringOrNull(character.roomId),
    commands: restrictToString(character.commands, ""),
    owner: restrictToStringOrNull(character.owner),
    speaking: restrictToBoolean(character.speaking, false),
    diceSkin: DiceSkinRecord(character.diceSkin),
    createdAt: restrictToNumber(character.updatedAt, 0),
    updatedAt: restrictToNumber(character.updatedAt, 0),
  };
};

const DiceSkinRecord = (
  diceSkin: DiceSkinSettings | undefined
): DiceSkinSettings => {
  return {
    d4: diceSkin?.d4,
    d6: diceSkin?.d6,
    d8: diceSkin?.d8,
    d10: diceSkin?.d10,
    d12: diceSkin?.d12,
    d20: diceSkin?.d20,
    d100: diceSkin?.d100,
  };
};
