import { Box, Button, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const RoomPackageCardNotFound = () => {
  const [t] = useTranslation();

  return (
    <StyledPaper>
      <Box mb={2}>
        <Typography variant="h6">{t("無効なURL")}</Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Typography variant="body2" color="textPrimary" paragraph>
          {t(
            "こちらのURLはご利用いただけません。以下の理由でURLが無効になっている可能性があります。"
          )}
        </Typography>
        <ul style={{ listStyle: "none" }}>
          <li>
            <Typography variant="body2" color="textPrimary">
              ・ルームデータの公開が停止されている
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="textPrimary">
              ・公開元のルームが削除されている
            </Typography>
          </li>
          <li>
            <Typography variant="body2" color="textPrimary">
              ・URLの入力やリンク先が間違っている
            </Typography>
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Button fullWidth variant="outlined" href="/">
          {t("トップへ戻る")}
        </Button>
      </Box>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  max-width: 448px;
  padding: 16px 24px;
`;

export default RoomPackageCardNotFound;
