import { memo } from "react";
import styled from "styled-components";
import { Typography, Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DeletedMember = () => {
  const [t] = useTranslation();
  return (
    <Container>
      <Paper>
        <Box p={2}>
          <Typography variant="h5" align="center" gutterBottom>
            {t("メンバー情報が削除されました")}
          </Typography>
          <Typography variant="body2" align="center">
            {t("このルームに入室するにはリロードしてください。")}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

const Container = styled.div`
  margin: auto auto;
`;

export default memo(DeletedMember);
