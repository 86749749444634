import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D12Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M26.2524 5.22471L18.0194 2.66669L9.77878 5.20205L4.679 11.8618L4.66602 20.1022L9.74631 26.7764L17.9793 29.3344L26.2199 26.799L31.3208 20.1393L31.3338 11.8988L26.2535 5.22471H26.2524Z"
        fill="#202020"
      />
      <path
        d="M26.2524 5.22471L18.0194 2.66669L9.77878 5.20205L4.679 11.8618L4.66602 20.1022L9.74631 26.7764L17.9793 29.3344L26.2199 26.799L31.3208 20.1393L31.3338 11.8988L26.2535 5.22471H26.2524ZM30.1465 11.8329L25.6885 13.4374L18.4793 8.45313V3.73047L25.6831 5.96823L30.1476 11.8319L30.1465 11.8329ZM22.394 22.0753H13.6036L10.887 14.1201L17.9988 9.20386L25.1106 14.1201L22.394 22.0753ZM10.347 5.94763L17.5583 3.72841V8.42636L10.2983 13.4456L5.86195 11.8031L10.347 5.94763ZM5.59787 12.6444L9.96926 14.2633L12.754 22.4182L10.0483 25.6786L5.58813 19.819L5.59895 12.6444H5.59787ZM10.8015 26.1822L13.4835 22.9507H22.4763L25.1658 26.2017L17.9804 28.4117L10.8015 26.1811V26.1822ZM25.9223 25.7002L23.235 22.4512L26.0359 14.2488L30.4127 12.6743L30.4019 19.853L25.9234 25.7002H25.9223Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D12Icon);
