import {
  AppBar,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import theme from "theme";

type RoleDescriptionDialogProps = {
  open: boolean;
  onClose: () => void;
};

const RoleDescriptionDialog = ({
  open,
  onClose,
}: RoleDescriptionDialogProps) => {
  const [t] = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }} fontSize="20px" fontWeight="bold">
            {t("ルームメンバーの権限")}
          </Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Section>
          <RoleName>{t("ルームマスター")}</RoleName>
          <Typography paragraph fontSize="14px">
            {t(
              "ルームを作成した本人のみに自動で与えられる権限です。ルームマスターの権限は変更できません。"
            )}
          </Typography>
          <Details>
            <Allowed>{t("できること")}：</Allowed>
            <Ul>
              <li>
                <TypographyListItem>
                  {t("すべての操作（PRO機能は加入者のみ）")}
                </TypographyListItem>
              </li>
            </Ul>
          </Details>
        </Section>
        <Section>
          <RoleName>{t("サブルームマスター")}</RoleName>
          <Typography paragraph fontSize="14px">
            {t(
              "ほぼルームマスターと同様の機能が使用できます。複数人がゲームマスターを務める場面などで有効的な権限です。ゲストユーザーはサブルームマスターになれません。"
            )}
          </Typography>
          <Details>
            <Allowed>{t("できること")}：</Allowed>
            <Ul>
              <li>
                <TypographyListItem>
                  {t("すべてのルームの編集機能（PRO機能は加入者のみ）")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("シーン切り替えなどのゲームマスター用機能")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>{t("ルームの設定")}</TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("チャットタブの追加、編集、削除")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("チャットの閲覧と書き込み")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("ルームマスター用のスラッシュコマンドの利用")}
                </TypographyListItem>
              </li>
            </Ul>
            <Divider />
            <Denied>{t("できないこと")}：</Denied>
            <Ul>
              <li>
                <TypographyListItem>
                  {t("メンバー権限の管理")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("ルームの出力に関する操作")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>{t("拡張パックの適用")}</TypographyListItem>
              </li>
            </Ul>
          </Details>
        </Section>
        <Section>
          <RoleName>{t("プレイヤー")}</RoleName>
          <Typography paragraph fontSize="14px">
            {t(
              "プレイヤーとして遊ぶ際に必要な機能が使用できます。従来の、ルーム作成者以外がルームにアクセスした場合と同様の操作ができます。"
            )}
          </Typography>
          <Details>
            <Allowed>{t("できること")}：</Allowed>
            <Ul>
              <li>
                <TypographyListItem>
                  {t("フィールド上にあるオブジェクトの操作")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("マイキャラクターの操作")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("チャットの閲覧と書き込み")}
                </TypographyListItem>
              </li>
            </Ul>
            <Divider />
            <Denied>{t("できないこと")}：</Denied>
            <Ul>
              <li>
                <TypographyListItem>{t("ルームの設定")}</TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("シーン切り替えなどのゲームマスター用機能の利用")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("チャットタブの追加、編集、削除")}
                </TypographyListItem>
              </li>
            </Ul>
          </Details>
        </Section>
        <Section>
          <RoleName>{t("見学")}</RoleName>
          <Typography paragraph fontSize="14px">
            {t(
              "見学者として参加するための権限です。ルーム内のすべてのチャットを閲覧することができます。"
            )}
          </Typography>
          <Details>
            <Allowed>{t("できること")}：</Allowed>
            <Ul>
              <li>
                <TypographyListItem>{t("フィールドの閲覧")}</TypographyListItem>
              </li>
              <li>
                <TypographyListItem>{t("チャットの閲覧")}</TypographyListItem>
              </li>
            </Ul>
            <Divider />
            <Denied>{t("できないこと")}：</Denied>
            <Ul>
              <li>
                <TypographyListItem>{t("フィールドの操作")}</TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("クリックアクション")}
                </TypographyListItem>
              </li>
              <li>
                <TypographyListItem>
                  {t("チャットへの書き込み")}
                </TypographyListItem>
              </li>
            </Ul>
          </Details>
        </Section>
        <Section>
          <RoleName>{t("アクセス不可")}</RoleName>
          <Typography paragraph fontSize="14px">
            {t(
              "ルームマスターがアクセスを許可しない限り、ルームへのアクセスができません。"
            )}
          </Typography>
          <Details>
            <Allowed>{t("できること")}：</Allowed>
            <Ul>
              <li>
                <TypographyListItem>{t("なし")}</TypographyListItem>
              </li>
            </Ul>
            <Divider />
            <Denied>{t("できないこと")}：</Denied>
            <Ul>
              <li>
                <TypographyListItem>{t("すべての機能")}</TypographyListItem>
              </li>
            </Ul>
          </Details>
        </Section>
      </DialogContent>
    </Dialog>
  );
};

const Section = styled.div`
  & + & {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${theme.palette.divider};
  }
`;

const RoleName = styled(Typography).attrs({
  fontSize: 20,
  fontWeight: "bold",
  paragraph: true,
})``;

const Details = styled.div`
  font-size: 14px;
  background: #202020;
  border-radius: 4px;
  padding: 16px;
`;

const Allowed = styled(Typography).attrs({
  color: "#00B960",
  fontWeight: "bold",
})``;
const Denied = styled(Typography).attrs({
  color: "#FF0000",
  fontWeight: "bold",
})`
  margin-top: 12px;
`;

const Ul = styled.ul`
  padding-left: 1.5em;
  margin: 8px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const TypographyListItem = styled(Typography).attrs({ fontSize: "14px" })``;

export default memo(RoleDescriptionDialog);
