import { CircularProgress } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { PropsWithChildren, useCallback, useState } from "react";
import styled from "styled-components";
import theme from "theme";

export const SettingAction = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 24px 0;

  ${theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

export const SettingActionDescription = styled.div`
  width: 100%;
  flex: 1 1;
`;

export const SettingActionButton = styled(Button).attrs<ButtonProps>(
  ({ color }) => ({
    variant: "outlined",
    color: color || "white",
    size: "large",
  })
)`
  width: 200px;
  margin-left: 16px;

  ${theme.breakpoints.down("md")} {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
`;

type SettingActionLoadingButtonProps = PropsWithChildren<{
  color?: ButtonProps["color"];
  disabled?: boolean;
  onClick: () => Promise<void>;
}>;

export const SettingActionLoadingButton = ({
  disabled,
  color,
  onClick,
  children,
}: SettingActionLoadingButtonProps) => {
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(() => {
    setLoading(true);
    onClick().finally(() => {
      setLoading(false);
    });
  }, [onClick, setLoading]);

  if (loading) {
    return (
      <SettingActionButton disabled>
        <CircularProgress color="primary" size={26.25} />
      </SettingActionButton>
    );
  } else {
    return (
      <SettingActionButton
        color={color}
        disabled={disabled}
        onClick={handleClick}
      >
        {children}
      </SettingActionButton>
    );
  }
};
