export const restrictToNumber = (value: any, defaultValue: number): number => {
  return typeof value === "number" ? value : defaultValue;
};

export const restrictToString = (value: any, defaultValue: string): string => {
  return typeof value === "string" ? value : defaultValue;
};

export const restrictToStringOrNull = (value: any): string | null => {
  return typeof value === "string" ? value : null;
};

export const restrictToBoolean = (
  value: any,
  defaultValue: boolean
): boolean => {
  return typeof value === "boolean" ? value : defaultValue;
};

export const restrictToArray = (
  value: any,
  defaultValue: Array<any>
): Array<any> => {
  return Array.isArray(value) ? value : defaultValue;
};

export const restrictToObject = (
  value: unknown,
  defaultValue: Object
): Object => {
  return typeof value === "object" && value != null ? value : defaultValue;
};
