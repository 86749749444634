import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D6Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M25.5126 4.47534H6.48639C5.36163 4.47534 4.44983 5.43779 4.44983 6.62504V26.7083C4.44983 27.8955 5.36163 28.858 6.48639 28.858H25.5126C26.6374 28.858 27.5492 27.8955 27.5492 26.7083V6.62504C27.5492 5.43779 26.6374 4.47534 25.5126 4.47534Z"
        fill="#202020"
      />
      <path
        d="M25.5142 29.3333H6.48689C5.11576 29.3333 4 28.1556 4 26.7083V6.62505C4 5.17775 5.11576 4 6.48689 4H25.5142C26.8853 4 28.0011 5.17775 28.0011 6.62505V26.7094C28.0011 28.1567 26.8853 29.3344 25.5142 29.3344V29.3333ZM6.48689 4.9507C5.61272 4.9507 4.90172 5.70119 4.90172 6.62393V26.7083C4.90172 27.631 5.61272 28.3815 6.48689 28.3815H25.5142C26.3883 28.3815 27.0993 27.631 27.0993 26.7083V6.62505C27.0993 5.70231 26.3883 4.95182 25.5142 4.95182H6.48689V4.9507Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D6Icon);
