import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D4Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M30.5851 28.4169L16.5342 4.30479C16.4238 4.11637 16.2195 4.00006 16 4.00006C15.7805 4.00006 15.5762 4.11753 15.4659 4.30479L1.41611 28.4169C1.30693 28.6053 1.30693 28.8391 1.41611 29.0275C1.52646 29.2159 1.73073 29.3322 1.95026 29.3322H30.0509C30.2705 29.3322 30.4747 29.2148 30.5851 29.0275C30.6954 28.8391 30.6954 28.6053 30.5851 28.4169Z"
        fill="#202020"
      />
      <path
        d="M30.5851 28.4169L16.5342 4.30479C16.4238 4.11637 16.2195 4.00006 16 4.00006C15.7805 4.00006 15.5762 4.11753 15.4659 4.30479L1.41611 28.4169C1.30693 28.6053 1.30693 28.8391 1.41611 29.0275C1.52646 29.2159 1.73073 29.3322 1.95026 29.3322H30.0509C30.2705 29.3322 30.4747 29.2148 30.5851 29.0275C30.6954 28.8391 30.6954 28.6053 30.5851 28.4169ZM16 21.2569L28.3029 28.3436H3.69826L16.0012 21.2569H16ZM16.4989 20.4008V6.2239L28.9345 27.5643L16.4989 20.4008ZM15.5011 6.2239V20.4008L3.06668 27.5643L15.5011 6.2239Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D4Icon);
