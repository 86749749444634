import { memo, useCallback } from "react";
import styled from "styled-components";
import { Card, CardActionArea, Grid, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { setRefererInternal } from "modules/referer";

type ExtentionItemProps = {
  productId: string;
  name: string;
  thumbnailUrl: string;
  purchased: boolean;
};

const ExtentionItem = ({
  productId,
  name,
  thumbnailUrl,
  purchased,
}: ExtentionItemProps) => {
  const onClickCard = useCallback(() => {
    setRefererInternal({ productId, referer: "room-page-extentions-dialog" });
  }, [productId]);

  return (
    <Grid item xs={4}>
      <Card>
        <CardActionArea
          href={`/games/${productId}`}
          target="_blank"
          onClick={onClickCard}
        >
          <Thumbnail src={thumbnailUrl} />
          {!purchased && (
            <Overlay>
              <LockIcon fontSize="large" />
            </Overlay>
          )}
        </CardActionArea>
      </Card>
      <Typography
        fontSize="12px"
        fontWeight="bold"
        color="textPrimary"
        margin="8px 0"
      >
        {name}
      </Typography>
    </Grid>
  );
};

const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.7);
`;

export default memo(ExtentionItem);
