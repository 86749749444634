import { useState, useCallback } from "react";

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const onOpen = useCallback(() => setOpen((open) => !open), [setOpen]);
  return {
    dialogProps: {
      open,
      onClose,
    },
    open: onOpen,
  };
};

export default useDialog;
