import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { MouseEvent, memo, useCallback, useState } from "react";

type LoadingPromiseButtonProps = Omit<ButtonProps, "onClick"> & {
  onClick: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
};

const LoadingPromiseButton = ({
  onClick,
  children,
  ...props
}: LoadingPromiseButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setLoading(true);
      onClick(event).finally(() => setLoading(false));
    },
    [onClick, setLoading]
  );

  if (loading) {
    return (
      <Button
        variant="outlined"
        fullWidth={props.fullWidth}
        size={props.size}
        style={props.style}
        disabled
      >
        <CircularProgress color="primary" size={24.5} />
      </Button>
    );
  }

  return (
    <Button onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default memo(LoadingPromiseButton);
