import { Box, Paper, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { PropsWithChildren } from "react";

type AlertMessageProps = PropsWithChildren<{}>;

const AlertMessage = ({ children }: AlertMessageProps) => {
  return (
    <Paper elevation={0} style={{ backgroundColor: "rgb(0 0 0 / 0.4)" }}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        padding="12px 16px"
      >
        <ErrorIcon color="secondary" sx={{ marginRight: "8px" }} />
        <Typography fontSize="12px" lineHeight="18px" color="text.secondary">
          {children}
        </Typography>
      </Box>
    </Paper>
  );
};

export default AlertMessage;
