export type DiceItem = {
  _id?: string;
  x: number;
  y: number;
  width: number;
  height: number;
  faces: number;
  value: number;
  closed: boolean;
  name: string;
  owner: string;
  changeCount: number;
  createdAt: number;
  updatedAt: number;
};
export type UpdateDiceItem = {
  _id?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  faces?: number;
  value?: number;
  closed?: boolean;
  name?: string;
  owner?: string;
  changeCount?: number;
  createdAt?: number;
  updatedAt?: number;
};

const numberValidate = (v: unknown, defVal: number): number => {
  const t = Number(v);
  return isFinite(t) ? t : defVal;
};

export const DiceItemRecord = (item: UpdateDiceItem): DiceItem => {
  return {
    ...item,
    x: numberValidate(item.x, -1),
    y: numberValidate(item.y, -1),
    width: numberValidate(item.width, 2),
    height: numberValidate(item.height, 2),
    faces: numberValidate(item.faces, 6),
    value: numberValidate(item.value, 1),
    closed: !!item.closed,
    name: item.name ?? "",
    owner: item.owner ?? "",
    changeCount: numberValidate(item.changeCount, 0),
    createdAt: item.createdAt ?? Date.now(),
    updatedAt: item.updatedAt ?? Date.now(),
  };
};
