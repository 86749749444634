import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from "@mui/material";
import { setRefererInternal } from "modules/referer";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

type RelatedProductCardProps = {
  productId: string;
  name: string;
  thumbnailUrl: string;
  description: string;
  referer:
    | "product-page-parent-card"
    | "product-page-extentions-card"
    | "product-page-related-items-card";
};

const RelatedProductCard = ({
  productId,
  name,
  thumbnailUrl,
  description,
  referer,
}: RelatedProductCardProps) => {
  const onClickCard = useCallback(() => {
    setRefererInternal({ productId, referer });
  }, [productId, referer]);

  return (
    <Card sx={{ display: "flex", marginBottom: "16px" }}>
      <CardActionArea
        component={Link}
        to={`/games/${productId}`}
        sx={{ display: "flex", overflow: "hidden" }}
        onClick={onClickCard}
      >
        <CardMedia
          component="img"
          sx={{ width: 140, height: "100%" }}
          image={thumbnailUrl}
        />
        <Box display="flex" flexDirection="column" padding="16px" flexGrow="1">
          <Typography fontSize="16px" marginBottom="4px">
            {name}
          </Typography>
          <Typography
            fontSize="14px"
            color="textSecondary"
            display="inline-block"
            textOverflow="ellipsis"
            lineHeight="19.6px"
            height="58.8px"
            overflow="hidden"
          >
            {description}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default memo(RelatedProductCard);
