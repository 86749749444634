import styled from "styled-components";
import {
  Container,
  Typography,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import StaticHeader from "containers/StaticHeader";
import { auth } from "initializer";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingSubmitButton from "containers/Login/LoadingSubmitButton";
import { sendPasswordResetEmail } from "firebase/auth";

type FormParams = {
  email: string;
};

const ResetPassword = () => {
  const [t] = useTranslation();
  const [sentTo, setSentTo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormParams>({
    defaultValues: { email: "" },
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    ({ email }: FormParams) => {
      setLoading(true);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setSentTo(email);
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") {
            setErrorMessage(t("メールアドレスの形式に誤りがあります。"));
            return;
          }

          setSentTo(email);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, setErrorMessage, t]
  );

  return (
    <StyledWrapper>
      <StaticHeader />
      <FirstSection>
        <Avatar
          src="/ccfolia.png"
          style={{ width: 128, height: 128, margin: "16px auto" }}
        />
        <StyledTitle
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {t("パスワード再設定メールを送る")}
        </StyledTitle>
        {sentTo ? (
          <PasswordArea>
            <Typography color="textPrimary" paragraph>
              {sentTo} {t("にパスワード再設定メールを送信しました。")}
            </Typography>
            <Typography color="textPrimary" paragraph>
              {t(
                "しばらくしてもメールが届かない場合は、迷惑メールフォルダにないかご確認ください。"
              )}
            </Typography>
            <Button href="/" color="secondary" variant="contained" fullWidth>
              トップページへ戻る
            </Button>
          </PasswordArea>
        ) : (
          <PasswordArea>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant="standard"
                type="email"
                label={t("メールアドレス")}
                fullWidth
                style={{ marginBottom: "16px" }}
                {...register("email", { required: true })}
              />
              {errorMessage && (
                <Typography color="error" variant="body2" paragraph>
                  {errorMessage}
                </Typography>
              )}
              <LoadingSubmitButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isValid}
                loading={loading}
              >
                {t("送信")}
              </LoadingSubmitButton>
            </form>
          </PasswordArea>
        )}
      </FirstSection>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background-size: cover;
  //min-height: 100vh;
  position: relative;
`;

const FirstSection = styled(Container)`
  padding: 96px 0 56px;
`;

const StyledTitle = styled(Typography)`
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 24px;
`;

const PasswordArea = styled.div`
  margin: 16px auto;
  padding: 16px;
  max-width: 470px;
  box-sizing: border-box;
`;

export default ResetPassword;
