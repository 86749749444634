import PDFViewerContent from "containers/PDFViewer/PDFViewerContent";
import { decodeBase64ToUnicode } from "modules/base64";
import { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

const PDFTool = () => {
  const { search } = useLocation();
  const url = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const param = searchParams.get("p");
    return param ? decodeBase64ToUnicode(param) : null;
  }, [search]);

  if (!url) {
    return null;
  }

  return (
    <PDFViewerContent
      open={true}
      url={url}
      width="100%"
      onClose={window.close}
      isPopupWindow
    />
  );
};

export default memo(PDFTool);
