import { Card, CardActionArea, Grid } from "@mui/material";
import { memo } from "react";
import styled from "styled-components";
import theme from "theme";
import { Link } from "react-router-dom";

type LibraryItemProps = {
  productId: string;
  name: string;
  thumbnailUrl: string;
};

const LibraryItem = ({ productId, name, thumbnailUrl }: LibraryItemProps) => {
  return (
    <Grid item xs={6} md={3} xl={2}>
      <Card>
        <CardActionArea component={Link} to={`/library/${productId}`}>
          <Thumbnail src={thumbnailUrl} />
          <Title>{name}</Title>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const Title = styled.p`
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  line-height: 20px;

  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 8px 16px;

  position: absolute;
  bottom: 16px;
  right: 16px;

  max-width: calc(100% - 64px);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default memo(LibraryItem);
