import styled from "styled-components";
import { ReactComponent as CcfoliaProSvg } from "./CcfoliaPro.svg";
import theme from "theme";

const CcfoliaProIcon = styled(CcfoliaProSvg).attrs({
  role: "img",
  "aria-label": "PRO",
})`
  margin-left: 8px;
  fill: ${theme.palette.text.primary};
`;

export default CcfoliaProIcon;
