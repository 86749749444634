import { SvgIcon } from "@mui/material";
import { memo } from "react";

const FacebookIcon = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default memo(FacebookIcon);
