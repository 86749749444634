import isValidUrl from "./isValidUrl";
import { Dispatch } from "redux";
import { addMessageCurrentRoom } from "stores/modules/entities.room.messages/operations";

export type ClickAction = ClickActionLink | ClickActionMessage;

export type ClickActionLink = {
  type: "link";
  url: string;
};

export type ClickActionMessage = {
  type: "message";
  text: string;
};

export const ClickActionRecord = (
  clickAction: Partial<ClickAction>
): ClickAction | null => {
  switch (clickAction.type) {
    case "link":
      return ClickActionLinkRecord(clickAction);
    case "message":
      return ClickActionMessageLink(clickAction);
    default:
      return null;
  }
};

export const ClickActionLinkRecord = (
  clickAction: Partial<ClickActionLink>
): ClickActionLink => {
  return {
    type: "link",
    url: clickAction.url || "",
  };
};

export const ClickActionMessageLink = (
  clickAction: Partial<ClickActionMessage>
): ClickActionMessage => {
  return {
    type: "message",
    text: clickAction.text || "",
  };
};

// handlers

const handleLinkAction = (clickAction: ClickActionLink) => {
  if (!isValidUrl(clickAction.url)) {
    return;
  }

  if (
    window.confirm(
      "下記 URL を開こうとしてます。本当によろしいですか？\n" + clickAction.url
    )
  ) {
    window.open(clickAction.url, "_blank");
  }
};

const handleMessageAction = (
  clickAction: ClickActionMessage,
  dispatch: Dispatch<any>
) => {
  if (clickAction.text === "") {
    return;
  }

  dispatch(addMessageCurrentRoom(clickAction.text, false));
};

export const handleClickAction = (
  clickAction: ClickAction,
  dispatch: Dispatch<any>
): void => {
  switch (clickAction.type) {
    case "link":
      return handleLinkAction(clickAction);
    case "message":
      return handleMessageAction(clickAction, dispatch);
  }
};
