import { useEffect } from "react";

const DEFAULT_DESCRIPTION =
  "スマートフォン対応のTRPGオンラインセッションツール クトゥルフ神話TRPGをはじめとした多数のTRPGシステムに対応 誰でも簡単に雰囲気のあるセッションを楽しむことができます！";

export const useMetaTagDescription = (
  description: string | undefined | null
) => {
  useEffect(() => {
    if (!description) {
      return;
    }

    updateDescription(description);
    return resetDescription;
  }, [description]);
};

const updateDescription = (text: string) => {
  const element = window.document.querySelector("meta[name='description']");
  if (element) {
    element.setAttribute("content", text);
  }
};

const resetDescription = () => {
  updateDescription(DEFAULT_DESCRIPTION);
};
