const storageUrl = process.env.REACT_APP_STORAGE_URL;
const cdnUrl = process.env.REACT_APP_CDN_URL;

const toCDNUrl = (baseUrl: string | null): string => {
  if (!baseUrl || !storageUrl || !cdnUrl) return "";
  return baseUrl.replace(storageUrl, cdnUrl);
};

export const isCDNUrl = (input: string): boolean => {
  if (!cdnUrl) return false;
  return toCDNUrl(input).indexOf(cdnUrl) === 0;
};

export default toCDNUrl;
