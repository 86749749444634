const ROLES = ["subowner", "player", "audience", "denied"] as const;

export type Role = typeof ROLES[number];

export type Member = {
  _id?: string;
  displayName: string;
  photoUrl: string | null;
  // name: string; // v2
  // color?: string; // v2
  role: Role | null; // white では number だったので注意
  isAnonymous: boolean;
  createdAt: number;
  updatedAt: number;
  typing?: string | null;
  group?: string | null;
};

export type UpdateMember = Partial<Member>;

export const isRole = (x: any): x is Role => {
  return ROLES.includes(x);
};

export const sanitizeRole = (x: unknown): Role | null => {
  return isRole(x) ? x : null;
};

export const MemberRecord = (member: UpdateMember): Member => {
  return {
    displayName: member.displayName || "",
    photoUrl: member.photoUrl || null,
    role: sanitizeRole(member.role),
    isAnonymous: member.isAnonymous ?? false,
    createdAt: member.createdAt || Date.now(),
    updatedAt: member.updatedAt || Date.now(),
    typing: member.typing,
    group: member.group,
  };
};
