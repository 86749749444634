import { createRoot } from "react-dom/client";
import "modules/array-at-polyfill";
import App from "./App";
import * as Sentry from "@sentry/react";
import { history } from "stores";

import "formdata-polyfill";
import "modules/padStart-polyfill";
import "./styles/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "modules/patch-arrayBuffer";
import "modules/resizeObserverPolyfill";
import { matchPath } from "react-router-dom";

window.process = {} as any;

if (!window.Blob) {
  window.Blob = Blob;
}

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
  const routes = [
    { path: "/", exact: true },
    { path: "/home", exact: true },
    { path: "/games", exact: true },
    { path: "/games/:productId", exact: true },
    { path: "/library", exact: true },
    { path: "/library/:productId", exact: true },
    { path: "/plans-closed-beta", exact: true },
    { path: "/plans", exact: true },
    { path: "/rooms/:roomId", exact: true },
    { path: "/rooms-v2/:roomId", exact: true },
    { path: "/rooms/:roomId/characters/:characterId", exact: true },
    { path: "/rooms/:roomId/chat", exact: true },
    { path: "/room-packages/:roomPackageId", exact: true },
    { path: "/reset-password", exact: true },
  ];

  const sampleRate = parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || "");
  const tracesSampleRate = parseFloat(
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || ""
  );

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath,
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "production",
    sampleRate: isFinite(sampleRate) ? sampleRate : 1,
    ignoreErrors: [
      /Document already exists: projects\/ccfolia-160aa\/databases\/\(default\)\/documents\/rooms\/[\w\-]+\/messages\//,
    ],
    tracesSampleRate: isFinite(tracesSampleRate) ? tracesSampleRate : 1,
  });
} else {
  console.log("DEVELOPMERNT MODE");
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/fsw.js", {
        scope: "/",
      });
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

registerServiceWorker();
