export type UserMediumDirectory = {
  name: string;
  order: number;
  createdAt: number;
  updatedAt: number;
};

export type UpdateUserMediumDirectory = Partial<UserMediumDirectory>;

export const UserMediumDirectoryRecord = (
  data: UpdateUserMediumDirectory
): UserMediumDirectory => {
  return {
    name: data.name || "BGM",
    order: data.order || 0,
    createdAt: data.createdAt || Date.now(),
    updatedAt: data.updatedAt || Date.now(),
  };
};
