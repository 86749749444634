import { Box, Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import XIcon from "./Icons/XIcon";
import LineIcon from "./Icons/LineIcon";
import FacebookIcon from "./Icons/FacebookIcon";
import ShareButton from "./ShareButton";

type ShareButtonsProps = {
  gameName: string;
};

const ShareButtons = ({ gameName }: ShareButtonsProps) => {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const shareUrl = makeShareUrl();
  const lineHref = makeLineShareURL();
  const xHref = makeXShareURL(gameName);
  const facebookHref = makeFaceboookShareURL();

  const copyUrlToClickboard = useCallback(() => {
    if (!navigator.clipboard) {
      alert(
        t(
          "エラー：このページのURLをクリップボードにコピーしようとしましたが、失敗しました。"
        )
      );
    }

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setOpen(true);
        window.setTimeout(() => {
          setOpen(false);
        }, 1000);
      })
      .catch(() =>
        alert(
          t(
            "エラー：このページのURLをクリップボードにコピーしようとしましたが、失敗しました。"
          )
        )
      );
  }, [shareUrl, setOpen, t]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="wrap"
      gap="8px"
      marginTop="24px"
      padding="16px"
      sx={{ background: "#2A2A2A" }}
    >
      <Typography color="textPrimary" marginRight="8px" whiteSpace="nowrap">
        {t("シェア")}
      </Typography>
      <Box display="flex" gap="8px">
        <ShareButton href={xHref} target="_blank" rel="noreferrer">
          <XIcon />
        </ShareButton>
        <ShareButton href={lineHref} target="_blank" rel="noreferrer">
          <LineIcon />
        </ShareButton>
        <ShareButton href={facebookHref} target="_blank" rel="noreferrer">
          <FacebookIcon />
        </ShareButton>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={t("URLをコピーしました")}
        >
          <ShareButton onClick={copyUrlToClickboard}>
            <ContentCopyIcon sx={{ color: "#fff" }} />
          </ShareButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const makeShareUrl = () => {
  return (
    window.location.origin + window.location.pathname + "?referer=clipboard"
  );
};

const makeLineShareURL = (): string => {
  const targetUrl =
    window.location.origin + window.location.pathname + "?referer=share-line";
  const getParams = new URLSearchParams();
  getParams.append("url", targetUrl);

  return `https://social-plugins.line.me/lineit/share?${getParams.toString()}`;
};

const makeXShareURL = (projectName: string): string => {
  const targetUrl =
    window.location.origin + window.location.pathname + "?referer=share-x";

  const getParams = new URLSearchParams();
  getParams.append("url", targetUrl);
  getParams.append("text", projectName);

  return `https://twitter.com/intent/tweet?${getParams.toString()}`;
};

const makeFaceboookShareURL = (): string => {
  const targetUrl =
    window.location.origin +
    window.location.pathname +
    "?referer=share-facebook";

  const getParams = new URLSearchParams();
  getParams.append("u", targetUrl);

  return `https://www.facebook.com/share.php?${getParams.toString()}`;
};

export default memo(ShareButtons);
