import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { memo, useMemo } from "react";
import styled from "styled-components";
import theme from "theme";
import { ReactComponent as ArrowRightIcon } from "./ArrowRight.svg";
import { ReactComponent as ArrowDownIcon } from "./ArrowDown.svg";
import { Product, getProducts } from "api";
import { shuffle } from "lodash-es";
import Footer from "containers/Footer";
import { useTranslation } from "react-i18next";
import GameCard from "./GameCard";
import useSWR from "swr";
import Carousel from "./Carousel";

const Games = () => {
  const [t] = useTranslation();

  const {
    data: products,
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useSWR("getProducts", getProducts);

  if (isLoadingProducts) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }

  if (!products || errorProducts) {
    return (
      <Container style={{ marginTop: "64px" }}>
        <Typography color="textPrimary" align="center">
          {t("データの取得に失敗しました。")}
        </Typography>
      </Container>
    );
  }

  return <GamesBody games={products} />;
};

const ONE_WEEK_MSEC = 7 * 24 * 60 * 60 * 1000;

type GamesBodyProps = {
  games: Product[];
};

const GamesBody = ({ games }: GamesBodyProps) => {
  const [t] = useTranslation();

  const gamesMain = useMemo(() => {
    const candidates = games.slice(0, 10);
    const threshold = Date.now() - ONE_WEEK_MSEC;
    const priorityGames = candidates.filter(
      (game) => Date.parse(game.publishedAt) >= threshold
    );
    const olderGames = candidates.filter(
      (game) => Date.parse(game.publishedAt) < threshold
    );

    const shuffledPriorityGames = shuffle(priorityGames);
    const shuffledOlderGames = shuffle(olderGames);
    return shuffledPriorityGames.concat(shuffledOlderGames);
  }, [games]);

  const otherProducts = useMemo(() => {
    return games.filter((game) => game.isOther);
  }, [games]);

  const notOtherProducts = useMemo(() => {
    return games.filter((game) => !game.isOther);
  }, [games]);

  return (
    <>
      <Carousel products={gamesMain} />
      <Container sx={{ marginTop: "32px" }} maxWidth="md">
        <Card elevation={0}>
          <CardActionArea
            href="https://bnn.co.jp/products/9784802512978"
            target="_blank"
          >
            <img
              width="100%"
              src="/images/games/banner-ccfolia-guidebook.png"
              alt="ココフォリアガイドブック"
            />
          </CardActionArea>
        </Card>
      </Container>
      <Container
        sx={{ paddingBottom: "56px", marginTop: "40px" }}
        maxWidth="md"
      >
        <SectionHeader>{t("新着ゲーム")}</SectionHeader>
        <Grid container spacing={3} alignItems="stretch" marginBottom="40px">
          {notOtherProducts
            .filter((game) => game.kind === "game")
            .map((game) => (
              <GameCard
                key={game.id}
                productId={game.id}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                category={game.category}
                price={game.price}
                to={`/games/${game.id}`}
                referer="store-top-page-list-new"
              />
            ))}
        </Grid>
        <SectionHeader>{t("追加・拡張コンテンツ")}</SectionHeader>
        <Grid container spacing={3} alignItems="stretch">
          {notOtherProducts
            .filter((game) => game.kind === "extention")
            .map((game) => (
              <GameCard
                key={game.id}
                productId={game.id}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                category={game.category}
                price={game.price}
                to={`/games/${game.id}`}
                referer="store-top-page-list-extention"
              />
            ))}
        </Grid>
        {otherProducts.length > 0 && (
          <>
            <SectionHeader marginTop="40px">
              {t("その他・ダウンロードコンテンツ等")}
            </SectionHeader>
            <Grid container spacing={3} alignItems="stretch">
              {otherProducts.map((game) => (
                <GameCard
                  key={game.id}
                  productId={game.id}
                  thumbnailUrl={game.thumbnailUrl}
                  name={game.name}
                  category={game.category}
                  price={game.price}
                  to={`/games/${game.id}`}
                  referer="store-top-page-list-other"
                />
              ))}
            </Grid>
          </>
        )}
      </Container>
      <Divider style={{ margin: "32px 0" }} />
      <Container
        style={{ marginTop: "56px", paddingBottom: "80px" }}
        maxWidth="md"
      >
        <Heading>{t("CCFOLIA GAMESについて")}</Heading>
        <Blocks>
          <Block>
            <img width="100%" src="/images/games/find.png" alt="" />
            <Typography
              color="textPrimary"
              paragraph
              style={{
                fontSize: "24px",
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              {t("みつけて")}
            </Typography>
            <Typography
              color="textSecondary"
              paragraph
              style={{ fontSize: "14px" }}
            >
              {t(
                "CCFOLIA GAMESではココフォリアで完結するゲームを紹介しています。お気に入りのゲームを見つけましょう。"
              )}
            </Typography>
          </Block>
          <ArrowRight />
          <ArrowDown />
          <Block>
            <img width="100%" src="/images/games/play.png" alt="" />
            <Typography
              color="textPrimary"
              paragraph
              style={{
                fontSize: "24px",
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              {t("あそんで")}
            </Typography>
            <Typography
              color="textSecondary"
              paragraph
              style={{ fontSize: "14px" }}
            >
              {t(
                "見つけたゲームでさっそく遊んでみましょう。ココフォリアにルームデータを反映すればすぐに遊べます。"
              )}
            </Typography>
          </Block>
          <ArrowRight />
          <ArrowDown />
          <Block>
            <img width="100%" src="/images/games/create.png" alt="" />
            <Typography
              color="textPrimary"
              paragraph
              style={{
                fontSize: "24px",
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              {t("つくってみよう")}
            </Typography>
            <Typography
              color="textSecondary"
              paragraph
              style={{ fontSize: "14px" }}
            >
              {t(
                "CCFOLIA GAMESはゲームを作ってみたいと思うその気持ちを応援します。"
              )}
            </Typography>
          </Block>
        </Blocks>
        {/* <Grid container spacing={4} mt="8px" justifyContent="center">
          <Grid item md={6}>
            <SupportThumbnail src="/images/games/posting-request.png" />
            <SupportHeading>CCFOLIA GAMESに作品を掲載</SupportHeading>
            <Typography
              variant="body2"
              paragraph
              color={theme.palette.text.secondary}
            >
              CCFOLIA GAMESは、皆様の製作したゲームをCCFOLIA
              GAMES上で紹介し、より多くの方に知って頂ける取り組みを進めています。
            </Typography>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              color="white"
              href="https://campaign.ccfolia.com/posting-request/"
              target="_blank"
            >
              詳しく見る
            </Button>
          </Grid>
          <Grid item md={6}>
            <SupportThumbnail src="/images/games/games-support.png" />
            <SupportHeading>
              {t("テーブルゲームのココフォリア対応相談")}
            </SupportHeading>
            <Typography
              variant="body2"
              paragraph
              color={theme.palette.text.secondary}
            >
              {t(
                "CCFOLIA GAMES STOREでは、製作者様とココフォリアで一緒に作り上げたゲームを販売します。ココフォリアと一緒にゲームを製作していただける製作者様を募集しています。"
              )}
            </Typography>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              color="white"
              href="https://campaign.ccfolia.com/games-support/"
              target="_blank"
            >
              {t("詳しく見る")}
            </Button>
          </Grid>
        </Grid> */}
      </Container>
      <Footer />
    </>
  );
};

const Blocks = styled.div`
  display: flex;
  gap: 8px;
  margin: 24px 0;

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

const Block = styled.div`
  flex: 1;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 4px;
`;

const SectionHeader = styled(Typography).attrs({
  color: "textPrimary",
  variant: "h2",
})`
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 24px;
`;

const ArrowRight = styled(ArrowRightIcon)`
  padding-left: 2px;
  flex-glow: 0;
  flex-shrink: 0;
  align-self: center;

  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  flex-glow: 0;
  flex-shrink: 0;
  align-self: center;

  ${theme.breakpoints.up("sm")} {
    display: none;
  }
`;

const Heading = styled(Typography).attrs({ variant: "h2" })`
  color: ${theme.palette.text.primary};
  font-size: 28px;
  font-weight: normal;
  text-align: center;
`;

// const SupportThumbnail = styled.img`
//   width: 100%;
//   border-radius: 4px;
//   background-color: white;
// `;

// const SupportHeading = styled(Typography).attrs({ variant: "h3" })`
//   color: ${theme.palette.text.primary};
//   font-size: 20px;
//   font-weight: normal;
//   margin-top: 24px;
//   margin-bottom: 16px;
// `;

export default memo(Games);
