export type RefererData = {
  referer: string;
  refererKind: "external" | "internal" | "unknown";
};

// BuyClick

type SetRefererBuyClickOptions = {
  productId: string;
  data: RefererData;
};

export const setRefererBuyClick = ({
  productId,
  data,
}: SetRefererBuyClickOptions) => {
  window.sessionStorage.setItem(keyBuyClick(productId), JSON.stringify(data));
};

export const getRefererBuyClick = (productId: string): RefererData | null => {
  const item = window.sessionStorage.getItem(keyBuyClick(productId));
  if (item == null) {
    return null;
  }

  window.sessionStorage.removeItem(keyBuyClick(productId));
  const data: RefererData = JSON.parse(item);
  return data;
};

const keyBuyClick = (productId: string) =>
  `ccfolia.refererBuyClick.${productId}`;

// Internal

type SetRefererInternalOptions = {
  productId: string;
  referer: string;
};

export const setRefererInternal = ({
  productId,
  referer,
}: SetRefererInternalOptions) => {
  const data: RefererData = {
    referer,
    refererKind: "internal",
  };

  // 別タブだと sessionStorage を引き継げないので localStorage にする
  window.localStorage.setItem(
    keyRefererInternal(productId),
    JSON.stringify(data)
  );
};

export const getRefererInternal = (productId: string): RefererData | null => {
  const key = keyRefererInternal(productId);
  const item = window.localStorage.getItem(key);
  if (item == null) {
    return null;
  }

  window.localStorage.removeItem(key);
  const data: RefererData = JSON.parse(item);
  return data;
};

const keyRefererInternal = (productId: string) =>
  `ccfolia.refererInternal.${productId}`;
