import * as appState from "./modules/app.state";
import * as appChat from "./modules/app.chat";
import * as appUser from "./modules/app.user";

import * as rooms from "./modules/entities.rooms";

import * as roomCharacters from "./modules/entities.room.characters";
import * as roomDecks from "./modules/entities.room.decks";
import * as roomDices from "./modules/entities.room.dices";
import * as roomEffects from "./modules/entities.room.effects";
import * as roomItems from "./modules/entities.room.items";
import * as roomMembers from "./modules/entities.room.members";
import * as roomMessages from "./modules/entities.room.messages";
import * as roomNotes from "./modules/entities.room.notes";
import * as roomScenes from "./modules/entities.room.scenes";
import * as roomSavedatas from "./modules/entities.room.savedatas";

import * as userFiles from "./modules/entities.user.files";
import * as userMedia from "./modules/entities.user.media";
import * as userMediumDirectories from "./modules/entities.user.mediumDirectories";
import * as userHistories from "./modules/entities.user.histories";
import * as userSetting from "./modules/entities.user.setting";

const interfaces = {
  ...appState.selectors,
  ...appState.operations,
  ...appChat.selectors,
  ...appChat.operations,
  ...appUser.selectors,
  ...appUser.operations,
  ...rooms.selectors,
  ...rooms.operations,
  ...roomCharacters.selectors,
  ...roomCharacters.operations,
  ...roomCharacters.records,
  ...roomDices.selectors,
  ...roomDices.operations,
  ...roomDecks.selectors,
  ...roomDecks.operations,
  ...roomEffects.selectors,
  ...roomEffects.operations,
  ...roomItems.selectors,
  ...roomItems.operations,
  ...roomMembers.selectors,
  ...roomMembers.operations,
  ...roomMessages.selectors,
  ...roomMessages.operations,
  ...roomNotes.selectors,
  ...roomNotes.operations,
  ...roomScenes.selectors,
  ...roomScenes.operations,
  ...roomSavedatas.selectors,
  ...roomSavedatas.operations,
  ...userFiles.selectors,
  ...userFiles.operations,
  ...userMedia.selectors,
  ...userMedia.operations,
  ...userMediumDirectories.selectors,
  ...userMediumDirectories.operations,
  ...userHistories.selectors,
  ...userHistories.operations,
  ...userSetting.operations,
};

export default interfaces;
