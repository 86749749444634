import {
  RefererData,
  getRefererInternal,
  setRefererInternal,
} from "modules/referer";
import { useCallback, useMemo } from "react";

export const useReferer = (productId: string): RefererData => {
  return useMemo(() => {
    const refererLocalStorage = getRefererInternal(productId);
    const refererSearchParams = getRefererFromURLSearchParams();

    const referer: RefererData =
      refererSearchParams || refererLocalStorage || REFERER_UNKNOWN;

    return referer;
  }, [productId]);
};

const REFERER_UNKNOWN: RefererData = {
  referer: "unknown",
  refererKind: "unknown",
};

const getRefererFromURLSearchParams = (): RefererData | null => {
  const searchParams = new URLSearchParams(window.location.search);
  const referer = searchParams.get("referer");

  if (!referer) {
    return null;
  }

  return { referer, refererKind: "external" };
};

type UseClickHandlerRefererProps = {
  productId: string;
  referer: string;
};

export const useClickHandlerReferer = ({
  productId,
  referer,
}: UseClickHandlerRefererProps): (() => void) => {
  return useCallback(() => {
    setRefererInternal({ productId, referer });
  }, [productId, referer]);
};
