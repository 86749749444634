import { Character } from "../entities.room.characters";
import { Deck } from "../entities.room.decks";
import { DiceItem } from "../entities.room.dices";
import { Effect } from "../entities.room.effects";
import { Item } from "../entities.room.items";
import { Note } from "../entities.room.notes";
import { Scene } from "../entities.room.scenes";
import { UpdateRoom } from "../entities.rooms";

// Savedata

export type Savedata = {
  _id?: string;
  name: string;
  thumbnail: string | null;
  snapshotVersion: "1" | "2";
  snapshotId: string;
  order: number;
  createdAt: number;
  updatedAt: number;
};

export type UpdateSavedata = Partial<Savedata>;

export const SavedataRecord = (savedata: UpdateSavedata): Savedata => {
  return {
    name: savedata?.name || "",
    thumbnail: savedata?.thumbnail || null,
    snapshotVersion: savedata?.snapshotVersion || "1",
    snapshotId: savedata?.snapshotId || "",
    order: savedata?.order || 0,
    createdAt: savedata?.createdAt || 0,
    updatedAt: savedata?.updatedAt || 0,
  };
};

// Snapshot

export type Snapshot = {
  _id?: string;
  room: UpdateRoom;
  characters: Record<string, Character>;
  decks: Record<string, Deck>;
  dices: Record<string, DiceItem>;
  effects: Record<string, Effect>;
  items: Record<string, Item>;
  notes: Record<string, Note>;
  scenes: Record<string, Scene>;
  createdAt: number;
};

export type Entities = Omit<Snapshot, "_id" | "createdAt">;

export type UpdateSnapshot = Partial<Snapshot>;

export const SnapshotRecord = (data: UpdateSnapshot): Snapshot => {
  return {
    room: data?.room || {},
    characters: data?.characters || {},
    decks: data?.decks || {},
    dices: data?.dices || {},
    effects: data?.effects || {},
    items: data?.items || {},
    notes: data?.notes || {},
    scenes: data?.scenes || {},
    createdAt: data?.createdAt || 0,
  };
};
