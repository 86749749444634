import config from "config/firebase";
import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  connectAuthEmulator,
  getAuth,
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from "firebase/firestore";

const firebaseApp = initializeApp(config);

export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});
export const rtdb = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
export const providers = {
  email: new EmailAuthProvider(),
  twitter: new TwitterAuthProvider(),
  google: new GoogleAuthProvider(),
} as const;
export const functions = getFunctions(firebaseApp);

auth.useDeviceLanguage();

if (
  process.env.NODE_ENV !== "production" &&
  process.env.REACT_APP_USE_EMR === "true"
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
