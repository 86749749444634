import { useEffect } from "react";
import version from "version";

const DEFAULT_TITLE = `CCFOLIA ${version} - TRPGオンラインセッションツール`;

export const useDocumentTitle = (title: string | undefined | null) => {
  useEffect(() => {
    if (!title) {
      return;
    }

    window.document.title = title;
    return () => {
      window.document.title = DEFAULT_TITLE;
    };
  }, [title]);
};
