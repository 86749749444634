import { Card, CardActionArea, Grid } from "@mui/material";
import { setRefererInternal } from "modules/referer";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

type GameCardProps = {
  productId: string;
  thumbnailUrl: string;
  name: string;
  category: string;
  price: number;
  to: string;
  referer:
    | "store-top-page-list-new"
    | "store-top-page-list-extention"
    | "store-top-page-list-other";
};

const GameCard = ({
  productId,
  thumbnailUrl,
  category,
  price,
  name,
  to,
  referer,
}: GameCardProps) => {
  const [t] = useTranslation();

  const onClickCard = useCallback(() => {
    setRefererInternal({ productId, referer });
  }, [productId, referer]);

  return (
    <Grid item xs={12} sm={4}>
      <Card>
        <CardActionArea component={Link} to={to} onClick={onClickCard}>
          <Thumbnail src={thumbnailUrl} alt="" />
          <CardBody>
            <Labels>
              <CategoryLabel>{category}</CategoryLabel>
              <Price>
                {price === 0 ? t("無料") : "¥" + price.toLocaleString("ja-JP")}
              </Price>
            </Labels>
            <Name>{name}</Name>
          </CardBody>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const CardBody = styled.div`
  padding: 13px;
  font-family: sans-serif;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CategoryLabel = styled.div`
  display: flex;
  align-items: center;

  font-size: 10px;
  line-height: 10px;
  color: #fff;

  padding: 0 8px;
  background-color: #000;
`;

const Price = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
`;

const Name = styled.div`
  font-size: 16px;
  line-height: 19px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default memo(GameCard);
