import { Button, Container, Link as LinkMui, Typography } from "@mui/material";
import StaticHeader from "containers/StaticHeader/StaticHeader";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const DeprecatedPreview = () => {
  const { roomId } = useParams<{
    roomId: string;
  }>();
  const [t] = useTranslation();

  return (
    <>
      <StaticHeader />
      <Container style={{ marginTop: "160px" }} maxWidth="sm">
        <Typography
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          ccfolia 2.0 previewの提供は
          <br />
          終了しました
        </Typography>
        <Typography
          color="textPrimary"
          align="center"
          style={{ marginBottom: "24px" }}
        >
          詳細は
          <LinkMui
            href="https://ccfolia.fanbox.cc/posts/4836526"
            target="_blank"
            underline="hover"
          >
            『ccfolia 2.0 に関する開発方針について』
          </LinkMui>
          をご覧ください。
        </Typography>
        <Button
          size="large"
          variant="contained"
          fullWidth
          component={Link}
          to={`/rooms/${roomId}`}
          color="secondary"
        >
          {t("ルームに戻る")}
        </Button>
      </Container>
    </>
  );
};

export default DeprecatedPreview;
