import { AppBar, Link, Paper, Toolbar, Typography } from "@mui/material";
import { Product, createProductCheckoutUrl } from "api";
import LoadingPromiseButton from "containers/LoadingPromiseButton";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type RoomPackageCardProductProps = {
  product: Product;
};

export const RoomPackageCardProduct = ({
  product,
}: RoomPackageCardProductProps) => {
  const [t] = useTranslation();
  const onClickBuy = useCallback(async () => {
    try {
      const { url } = await createProductCheckoutUrl(product.id);
      window.location.href = url;
    } catch {
      window.alert(t("決済ページの取得に失敗しました"));
    }
  }, [product.id, t]);

  return (
    <StyledPaper>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6">
            {t("ゲームが購入されていません")}
          </Typography>
        </Toolbar>
      </AppBar>
      <PaperContent>
        <Labels>
          <CategoryLabel>{product.category}</CategoryLabel>
        </Labels>
        <Title>{product.name}</Title>
        <div>
          <SpecRow>
            <Spec>
              <SpecDesc>{t("プレイ人数")}：</SpecDesc>
              {product.players}
            </Spec>
            <Spec>
              <SpecDesc>{t("プレイ時間")}：</SpecDesc>
              {product.playTimes}
            </Spec>
          </SpecRow>
          <SpecRow>
            <Spec>
              <SpecDesc>{t("開発元")}：</SpecDesc>
              {product.developer}
            </Spec>
            <Spec>
              <SpecDesc>{t("リリース日")}：</SpecDesc>
              {product.releasedAt}
            </Spec>
          </SpecRow>
        </div>
        <Thumbnail src={product.thumbnailUrl} />
        <Typography fontSize="14px" marginBottom="24px">
          {t(
            "このURLからルームを作成するには、上記のゲームをご購入いただく必要があります。"
          )}
        </Typography>
        <LoadingPromiseButton
          variant="contained"
          color="secondary"
          fullWidth
          onClick={onClickBuy}
        >
          <div>
            <Typography fontSize={14} lineHeight={1.4}>
              {t("購入する")}
            </Typography>
            <Typography fontSize={12} lineHeight={1.4}>
              ¥{product.price.toLocaleString("ja-JP")}
            </Typography>
          </div>
        </LoadingPromiseButton>
        <Typography
          variant="body2"
          fontSize="12px"
          color="textSecondary"
          marginTop="24px"
          marginBottom="14px"
        >
          {t(
            "※ ゲームを購入しているにも関わらず、ルームの作成ができない場合はこちらにお問合せください。"
          )}
          <Link href="mailto:contact@ccfolia.com">contact@ccfolia.com</Link>
        </Typography>
      </PaperContent>
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  max-width: 448px;
`;

const PaperContent = styled.div`
  padding: 16px 24px;
`;

const Thumbnail = styled.img`
  width: 100%;
  margin: 16px 0;
  border-radius: 4px;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CategoryLabel = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 20px;
  color: #fff;

  padding: 0 4px;
  background-color: #000;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 8px;
`;

const SpecRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const Spec = styled(Typography).attrs({ color: "textSecondary" })`
  font-size: 14px;
`;

const SpecDesc = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

export default RoomPackageCardProduct;
