import { SvgIcon } from "@mui/material";
import { memo } from "react";

const D100Icon = () => {
  return (
    <SvgIcon viewBox="0 0 32 32">
      <path
        d="M21.1431 4.00006L15.9002 7.82282L10.4487 4.00006L0.000976562 11.6184V18.9537L10.4479 28.0001L15.9002 23.4603L21.1422 28.0001L32.001 18.9588V11.6134L21.1431 4.00006Z"
        fill="#202020"
      />
      <path
        d="M19.3071 10.6958C19.0941 10.5569 18.8819 10.3907 18.6689 10.2306L19.3071 10.6958Z"
        fill="#202020"
      />
      <path
        d="M17.2783 21.6667C17.4754 21.4371 17.737 21.3213 17.9889 21.2054L17.2783 21.6667Z"
        fill="#202020"
      />
      <path
        d="M15.5875 23.1893C15.812 23.0211 16.0356 22.8731 16.2601 22.6959L15.5875 23.1893Z"
        fill="#202020"
      />
      <path
        d="M21.1431 4.00006L15.9011 7.82282L10.4487 4.00006L0.000976562 11.6184V18.9537L10.4479 28.0001L15.9002 23.4603L21.1422 28.0001L32.001 18.9588V11.6134L21.1431 4.00006ZM20.6906 5.34649L18.8186 9.86814L16.6117 8.3203L20.6906 5.34548V5.34649ZM20.5554 12.0917V18.3717L16.7469 19.0071L11.0197 5.4059L20.5554 12.0917ZM0.752233 12.0867L9.99623 5.34649L4.35561 18.9719L0.753117 18.3707V12.0867H0.752233ZM10.0767 26.6023L1.7651 19.4059L4.48907 19.8601L10.0767 24.6778V26.6033V26.6023ZM5.05914 19.277L10.4974 6.14407L16.0664 19.3707L10.4487 23.9235L5.06003 19.278L5.05914 19.277ZM10.8279 24.6657L16.7407 19.8732L19.4815 19.416L10.827 26.6214V24.6657H10.8279ZM20.771 26.6023L16.533 22.9326L17.6599 21.994L20.771 24.6758V26.6013V26.6023ZM18.2936 21.4673L21.3066 18.9588V11.6134L19.4621 10.3203L21.1908 6.14407L26.7599 19.3707L21.1422 23.9235L18.2936 21.4673ZM21.5223 26.6224V24.6667L27.4351 19.8742L30.1759 19.417L21.5214 26.6224H21.5223ZM31.2497 18.3717L27.4413 19.0071L21.7141 5.4059L31.2497 12.0917V18.3717Z"
        fill="#ACACAC"
      />
    </SvgIcon>
  );
};

export default memo(D100Icon);
